import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.mode === 'light' ? '#f7f9ff' : '#0d0c2d',
  padding: '36px 135px',
  gap: '48px',
}))

export const Content = styled(Box)(() => ({
  display: 'flex',
  gap: '120px',
}))

export const StyledButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-start',
  gap: '8px',
  height: '20px',
  border: 'none',
  cursor: 'pointer',
  padding: '0',
  background: 'none',
  fontSize: '14px',
  fontWeight: '300',
  color: theme.palette.mode === 'light' ? '#000021' : '#ffffff',

  svg: {
    width: '20px',
    height: '20px',
    transform: 'rotateZ(90deg)',
    path: {
      stroke: theme.palette.mode === 'light' ? '#000021' : '#ffffff',
    },
  },
}))
