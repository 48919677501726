import React, { ChangeEvent, useCallback } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { Box, Divider, SelectChangeEvent, Table } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from 'components/ui/TablePagination'
import Text from 'components/ui/Text'
import { ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { Leaderboard } from 'types/leaderbord'

import map from 'lodash/map'

import LeaderboardTableRow from './LeaderboardTableRow'
import { Container, LeaderboardTableContainer } from './styles'

type Props = {
  count?: number
  currentPage?: number
  leaderboardData?: Leaderboard[]
  loading?: boolean
  pageCount?: number
  rowsPerPage?: number
  onChangePage?: (event: ChangeEvent<unknown>, value: number) => void
  onRowsPerPageChange?: (event: SelectChangeEvent<unknown>) => void
}

function LeaderboardTable({
  currentPage = 1,
  count = 0,
  leaderboardData,
  loading,
  pageCount,
  rowsPerPage = ROWS_PER_PAGE_DEFAULT,
  onChangePage,
  onRowsPerPageChange,
}: Props) {
  const { me } = useAppContext()
  const { ref: resizeDetectorRef, height: tableContainerHeight } =
    useResizeDetector({
      refreshMode: 'throttle',
      refreshRate: 250,
      handleWidth: false,
    })

  const getValidatorRank = useCallback(
    (index: number) => index + 1 + (currentPage - 1) * rowsPerPage,
    [currentPage, rowsPerPage],
  )

  if (loading)
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <CircularProgress size={30} />
      </Box>
    )

  if (!leaderboardData?.length)
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <Text title3>The list is empty</Text>
      </Box>
    )

  return (
    <>
      <Container ref={resizeDetectorRef}>
        <LeaderboardTableContainer
          sx={{
            height: `${tableContainerHeight || 0}px`,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width={0.5}>#</TableCell>
                <TableCell>User</TableCell>
                <TableCell align="right" width={1}>
                  Points earned
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(leaderboardData, (leaderboard, index) => (
                <LeaderboardTableRow
                  active={leaderboard.validatorId === me?.id}
                  key={leaderboard.validatorId}
                  leaderboard={leaderboard}
                  rank={getValidatorRank(index)}
                />
              ))}
            </TableBody>
          </Table>
        </LeaderboardTableContainer>
      </Container>
      <Divider sx={{ my: 0.5 }} />
      <TablePagination
        count={count}
        currentPage={currentPage}
        pageCount={pageCount}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  )
}

export default LeaderboardTable
