import React, { useEffect, useMemo } from 'react'

import * as logosMap from 'assets/logos'
import { Providers } from 'graphql/nodes/types'
import { DateTime } from 'luxon'
import { getRandomLogos, setRandomLogos } from 'services/randomLogos'

import sampleSize from 'lodash/sampleSize'

import {
  CompaniesContainer,
  CompaniesWrapper,
  CompanyIconBox,
  Subtitle,
} from './styles'

interface Props {
  providersArr?: Providers[]
}

const logos = Object.values(logosMap)

function getCurrentDay() {
  return DateTime.local().toSQLDate()
}

function getLogos() {
  return {
    day: getCurrentDay(),
    logos: sampleSize(logos, 10).map(logo => logos.indexOf(logo)),
  }
}

// todo here need do update with providers
// eslint-disable-next-line no-empty-pattern
function CompaniesBox({}: Props) {
  useEffect(() => {
    const randomLogos = getRandomLogos()

    if (randomLogos?.day !== getCurrentDay()) setRandomLogos(getLogos())
  }, [])

  const logoIndexes = useMemo(() => {
    const storedRandomLogos = getRandomLogos()

    if (storedRandomLogos?.day !== getCurrentDay()) {
      const randomLogos = getLogos()
      setRandomLogos(randomLogos)
      return randomLogos
    }

    return storedRandomLogos
  }, [])

  return (
    <CompaniesWrapper>
      <Subtitle align="left" mb={2}>
        Recent Validators participation
      </Subtitle>
      <CompaniesContainer>
        {logoIndexes.logos?.map((logoIndex: number) => (
          <CompanyIconBox key={logoIndex}>
            <img alt={logos[logoIndex]} src={logos[logoIndex]} width={80} />
          </CompanyIconBox>
        ))}
      </CompaniesContainer>
    </CompaniesWrapper>
  )
}

export default CompaniesBox
