import React, { useMemo } from 'react'

import { Box, Divider } from '@mui/material'
import MorpheusFormIcon from 'assets/icons/morpheus_form_icon_light.svg'
import Text from 'components/ui/Text'
import { NodeEarning, NodeStatus } from 'graphql/nodes/types'
import { StakingPeriod, StakingTermsLabel } from 'graphql/stakingTerms/types'
import { getDate } from 'utils/date'
import { formatNumber } from 'utils/numbers'

import { Amount, InfoWrapper, RewardBlock } from '../styles'

interface Props {
  closeAt?: string
  createdAt?: string
  earnedRewards?: NodeEarning
  period?: StakingPeriod
  projectedRewards?: NodeEarning
  stakedAmount?: number
  status?: NodeStatus
  reward?: number
  penalty?: number
}

function Reward({
  projectedRewards,
  earnedRewards,
  period,
  closeAt,
  createdAt,
  stakedAmount,
  status,
  reward = 0,
  penalty = 0,
}: Props) {
  const rewardAPY = useMemo(() => {
    if (status === NodeStatus.ENABLED) return 0
    if (!stakedAmount || !reward) return 0
    return (stakedAmount / reward) * 100
  }, [reward, stakedAmount, status])

  const projectedRewardsApy = useMemo(
    () => (projectedRewards?.apy || 0) * 100,
    [projectedRewards],
  )
  const earnedRewardsApy = useMemo(
    () => (earnedRewards?.apy || 0) * 100,
    [earnedRewards],
  )

  return (
    <InfoWrapper>
      {status === NodeStatus.ENABLED ? (
        <RewardBlock>
          <Text body2>Accrued rewards</Text>
          <Box mb={0.5} mt={1} sx={{ display: 'flex' }}>
            <img alt="MNW" src={MorpheusFormIcon} width={25} />
            <Amount color={'colors.font.default'} ml={1}>
              {formatNumber(earnedRewards?.earning, '0.00')}
            </Amount>
          </Box>
          <Text body4>
            {`MNW ${earnedRewards?.perDay?.toFixed(2) || 0} per day`}
          </Text>
          <Divider sx={{ mt: 2.5, mb: 2 }} />
          <Amount color={'colors.font.positive'}>
            {`${earnedRewardsApy.toFixed(2)}% APY`}
          </Amount>
        </RewardBlock>
      ) : (
        <RewardBlock>
          <Text body2>Rewards</Text>
          <Box mb={0.5} mt={1} sx={{ display: 'flex' }}>
            <img alt="MNW" src={MorpheusFormIcon} width={25} />
            <Amount color={'colors.font.default'} ml={1}>
              {formatNumber(reward, '0.00')}
            </Amount>
          </Box>
          <Text body4>{`Penalty MNW ${penalty?.toFixed(2)}`}</Text>
          <Divider sx={{ mt: 2.5, mb: 2 }} />
          <Amount color={'colors.font.positive'}>
            {`${rewardAPY?.toFixed(2)}% APY`}
          </Amount>
        </RewardBlock>
      )}

      <RewardBlock>
        <Text body2>Projected rewards</Text>
        <Box mb={0.5} mt={1} sx={{ display: 'flex' }}>
          <img alt="MNW" src={MorpheusFormIcon} width={25} />
          <Amount color={'colors.font.default'} ml={1}>
            {formatNumber(projectedRewards?.earning, '0.00')}
          </Amount>
        </Box>
        <Text body4>
          {`MNW ${projectedRewards?.perDay?.toFixed(2) || 0} per day`}
        </Text>
        <Divider sx={{ mt: 2.5, mb: 2 }} />
        <Amount color={'colors.font.positive'}>
          {`${projectedRewardsApy.toFixed(2)}% APY`}
        </Amount>
      </RewardBlock>
      <RewardBlock>
        <Text body2 sx={{ mb: 1 }}>
          Stake progress
        </Text>
        <Text body4 secondary>
          Period
        </Text>
        <Text body2 sx={{ my: 0.5 }}>
          {period ? StakingTermsLabel[period] : '__'}
        </Text>
        <Text body4 secondary>
          {!!createdAt && getDate(createdAt)} - {!!closeAt && getDate(closeAt)}
        </Text>
      </RewardBlock>
    </InfoWrapper>
  )
}

export default Reward
