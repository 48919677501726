import { commonChartOptions } from 'constants/chartOptions'
import { getFormattedNumber } from 'constants/number'

export const createCircularChartOptions = () => ({
  ...commonChartOptions,
  chartArea: { width: '100%', height: 180 },
  pieSliceText: 'none',
  pieSliceBorderColor: 'none',
})

type Point = {
  amount: number
  percentage: number
}

export const generateCircularChartTooltipBlock = (
  point: Point,
  theme: 'light' | 'dark',
) =>
  `
		<div style="background-color: ${
      theme === 'light' ? '#fff' : '#262546'
    }; padding: 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-size: 10px; padding: 5px; margin: 0;">${getFormattedNumber(
    point?.amount,
  )}</p>
			<p style="color: ${
        theme === 'light' ? 'rgba(0, 0, 33, 1)' : 'white'
      }; font-weight: bold; padding: 5px; margin: 0;">${point?.percentage}%</p>
		</div>
		`
