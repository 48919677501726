import React, { useContext, useMemo } from 'react'
import { Chart } from 'react-google-charts'

import LensIcon from '@mui/icons-material/Lens'
import { useTheme } from '@mui/material'
import { Box } from '@mui/system'
import Text from 'components/ui/Text'
import { ThemeContext } from 'context/themeMode'
import { createCircularChartOptions } from 'utils/circularChart'

// todo update type
interface Props {
  chartData?: any
  legendTitles?: string[]
}
function CirculationChart({ chartData, legendTitles }: Props) {
  const materialTheme = useTheme()

  const customTheme = useContext(ThemeContext)

  const chartOptions = createCircularChartOptions()

  const renderLegend = useMemo(() => {
    return (
      <Box
        display="flex"
        gap={3}
        sx={{
          fontSize: '12px',
        }}
      >
        <Box alignItems="center" display="flex" gap={1}>
          <LensIcon color="primary" sx={{ fontSize: '8px' }} />
          {legendTitles?.[0]}
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <LensIcon color="warning" sx={{ fontSize: '8px' }} />
          {legendTitles?.[1]}
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <LensIcon
            sx={{
              fontSize: '8px',
              color:
                customTheme.mode === 'light' ? '#C5D1EA' : 'colors.bg.dropdown',
            }}
          />
          {legendTitles?.[2]}
        </Box>
      </Box>
    )
  }, [customTheme, legendTitles])

  if (!chartData.length)
    return (
      <Box alignItems="center" display="flex" flex={1}>
        <Text secondary>No available data</Text>
      </Box>
    )

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Box mb={2} width="100%">
        <Chart
          chartType="PieChart"
          data={chartData}
          height="100%"
          options={{
            ...chartOptions,
            colors: [
              materialTheme.palette.primary.main,
              materialTheme.palette.warning.main,
              // todo need to discus with designer
              customTheme.mode === 'light'
                ? '#C5D1EA'
                : // @ts-ignore
                  materialTheme.palette.colors.bg.dropdown,
            ],
          }}
        />
      </Box>
      {renderLegend}
    </Box>
  )
}

export default CirculationChart
