import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import RefreshIcon from '@mui/icons-material/Refresh'
import { AlertTitle, Box, Tabs } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as NotHaveNodesDM } from 'assets/icons/dont_have_nodes_dark.svg'
import { ReactComponent as NotHaveNodesLM } from 'assets/icons/dont_have_nodes_light.svg'
import { Alert } from 'components/alert'
import { AlertMessage, AlertType } from 'components/alert/types'
import { ResetNodeLockModal } from 'components/blocks/modals'
import ContentBox from 'components/ContentBox'
import { DotCarousel } from 'components/dotCarousel'
import { GeneralStats } from 'components/GeneralStats'
import { IsNodeActive } from 'components/isNodeActive'
import { NodeStats } from 'components/nodeStats'
import Subheader from 'components/Subheader'
import StyledTab from 'components/ui/StyledTab'
import {
  INACTIVITY_NODE_PERIOD_MINUTES,
  NODE_POLL_INTERVAL,
} from 'constants/params'
import { ThemeContext } from 'context/themeMode'
import { SubmitButton, Subtitle, Title } from 'globalStyles'
import { REMOVE_VALIDATOR_NODE_APP_ID } from 'graphql/nodes/mutation'
import { NODE_BY_ADDRESS } from 'graphql/nodes/queries'
import { NodeData } from 'graphql/nodes/types'
import { DateTime } from 'luxon'
import { getErrorMessage } from 'utils/error'

function a11yProps(index: number) {
  return {
    id: `stats-tab-${index}`,
    'aria-controls': `stats-tabpanel-${index}`,
  }
}
function DetailNodePage() {
  const [tab, setTab] = useState(1)

  const mode = useContext(ThemeContext)

  const { address } = useParams()

  const isDarkMode = mode.mode === 'dark'
  const NotHaveNodes = isDarkMode ? NotHaveNodesDM : NotHaveNodesLM

  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const [resetNodeModalOpened, setResetNodeModalOpened] = useState(false)

  useEffect(() => {
    setTab(1)
  }, [address])

  const { data, loading, refetch } = useQuery(NODE_BY_ADDRESS, {
    variables: { address },
    pollInterval: NODE_POLL_INTERVAL,
    fetchPolicy: 'network-only',
    skip: !address,
  })

  const [resetNode] = useMutation(REMOVE_VALIDATOR_NODE_APP_ID)

  const nodeData: NodeData = useMemo(() => data?.nodeByAddress || {}, [data])

  const isActive = useMemo(() => {
    if (!nodeData?.lastActivity || !nodeData?.active) return false

    return (
      DateTime.now()
        .diff(DateTime.fromISO(nodeData?.lastActivity))
        .as('minutes') < INACTIVITY_NODE_PERIOD_MINUTES
    )
  }, [nodeData])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const handleResetNode = useCallback(
    async (ok?: boolean) => {
      setResetNodeModalOpened(false)

      if (!ok) return

      try {
        await resetNode()
      } catch (error) {
        setAlert({
          isOpen: true,
          title: getErrorMessage(error),
          color: AlertType.ERROR,
        })
      }
    },
    [resetNode],
  )

  if (loading)
    return (
      <ContentBox alignItems="center" justifyContent="center">
        <CircularProgress style={{ margin: '0 auto' }} />
      </ContentBox>
    )

  return nodeData ? (
    <>
      <Subheader py={4}>
        <IsNodeActive
          active={isActive}
          canResetNode={tab === 1 && !!nodeData.validator.nodeAppId}
          period={nodeData.period}
          projectedRewards={nodeData.projectedRewards.earning}
          onReset={() => setResetNodeModalOpened(true)}
        />
        {/* <ShareButton disabled onClick={() => {}}>
                <ShareOutlinedIcon
                  fontSize="small"
                  sx={{ marginRight: '11px' }}
                />
                Share
              </ShareButton> */}
      </Subheader>
      <ContentBox>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2.5 }}>
          <Tabs
            indicatorColor="primary"
            sx={{ minHeight: '40px' }}
            value={tab}
            onChange={handleChange}
          >
            <StyledTab
              label="General Stats"
              sx={{
                mr: 4,
              }}
              {...a11yProps(0)}
            />
            <StyledTab label="My nodes" {...a11yProps(1)} />
          </Tabs>
        </Box>
        {tab === 0 && <GeneralStats />}
        {tab === 1 && (
          <NodeStats
            address={address as string}
            nodeData={nodeData}
            onRefetchNode={refetch}
          />
        )}
      </ContentBox>
      <Alert
        severity={alert?.color}
        sx={{ width: '100%' }}
        onClose={() => setAlert(prevState => ({ ...prevState, isOpen: false }))}
      >
        <AlertTitle sx={{ textAlign: 'left' }}>{alert.title}</AlertTitle>
        {alert.text}
      </Alert>

      <ResetNodeLockModal
        isOpen={resetNodeModalOpened}
        onClose={handleResetNode}
      />
    </>
  ) : (
    <ContentBox alignItems="center" justifyContent="center">
      <NotHaveNodes />
      <Title>Please wait a while</Title>
      <Subtitle style={{ textAlign: 'center' }}>
        We are collecting node data <DotCarousel />
      </Subtitle>
      <Box sx={{ maxWidth: '320px', margin: '0 auto' }}>
        <SubmitButton onClick={() => refetch().then()}>
          Refresh
          <RefreshIcon />
        </SubmitButton>
      </Box>
    </ContentBox>
  )
}

export default DetailNodePage
