export const calculatePercentage = (
  partAmount?: number,
  total?: number,
): number => {
  if (!partAmount || !total) {
    return 0
  }

  const percentage = (partAmount / total) * 100
  return Math.round(percentage * 100) / 100
}
