import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { ReactComponent as TestQueryRunIcon } from 'assets/icons/test_query_run_icon.svg'
import InstructionsLink from 'components/InstructionsLink'
import { LoadingButton } from 'components/loadingButton'
import { SubmitButton, Subtitle, Title } from 'globalStyles'
import { appConnectedQuery } from 'graphql/validators/queries'

type Props = {
  onErrorTest?: () => void
  onSuccessTest?: () => void
}

function TestQuery({ onErrorTest, onSuccessTest }: Props) {
  const [shouldLoad, setShouldLoad] = useState<boolean>(false)

  const { data, loading } = useQuery(appConnectedQuery, {
    fetchPolicy: 'network-only',
    skip: !shouldLoad,
  })

  useEffect(() => {
    if (data) {
      if (data.appConnected === true) {
        onSuccessTest?.()
      } else {
        onErrorTest?.()
      }
    }
  }, [data, onErrorTest, onSuccessTest])

  return (
    <>
      {loading && (
        <>
          <TestQueryRunIcon />
          <Title>A test query is running</Title>
          <Subtitle style={{ textAlign: 'center' }}>
            Please wait a while
          </Subtitle>
          <Box sx={{ width: '350px', margin: '0 auto' }}>
            <LoadingButton disabled loading text={'Running'} />
          </Box>
        </>
      )}

      {!loading && !shouldLoad && (
        <>
          <TestQueryRunIcon />
          <Title style={{ textAlign: 'center' }}>
            Finish setup
            <br />
            CLI App
          </Title>
          <Subtitle style={{ textAlign: 'center' }}>
            <div>1. Run CLI App</div>
            <div>2. Authenticate</div>
            <div>3. Click &quot;Ready to stake&quot;</div>
          </Subtitle>
          <SubmitButton
            style={{ maxWidth: '320px' }}
            onClick={() => setShouldLoad(true)}
          >
            Ready to stake
          </SubmitButton>
        </>
      )}
      <InstructionsLink />
    </>
  )
}

export default TestQuery
