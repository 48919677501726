import React from 'react'

import { ReactComponent as TestQueryErrorIcon } from 'assets/icons/test_query_error_icon.svg'
import InstructionsLink from 'components/InstructionsLink'
import { SubmitButton, Subtitle, Title } from 'globalStyles'

type Props = {
  onStartAgain?: () => void
}

function TestQueryError({ onStartAgain }: Props) {
  return (
    <>
      <TestQueryErrorIcon />
      <Title mt={1}>Oops, something went wrong</Title>
      <Subtitle style={{ textAlign: 'center' }}>
        You first need to install CLI App and log in. Or you can try again later
        or contact our support
      </Subtitle>
      <SubmitButton style={{ maxWidth: '320px' }} onClick={onStartAgain}>
        Go back
      </SubmitButton>
      <InstructionsLink />
    </>
  )
}

export default TestQueryError
