import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { useQuery } from '@apollo/client'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { CompaniesBox } from 'components/companiesBox'
import { NodesTotalBox } from 'components/NodesTotalBox'
import Text from 'components/ui/Text'
import { getFormattedNumber } from 'constants/number'
import { calculatePercentage } from 'constants/percentage'
import { ThemeContext } from 'context/themeMode'
import {
  SYSTEM_DAILY_STATS_DATASET,
  SYSTEM_DAILY_STATS_GRAPH_DATA,
} from 'graphql/systemDailyStats/queries'
import { DateTime } from 'luxon'
import { generateCircularChartTooltipBlock } from 'utils/circularChart'

import isUndefined from 'lodash/isUndefined'

import BridgedTokenChart from './Charts/bridgedTokenChart'
import CirculationChart from './Charts/circulationChart'
import TokenStakedBarChart from './Charts/tokenStakedBarChart'
import { ChartContainer } from './styles'

export interface Range {
  from: DateTime | null
  to: DateTime | null
}

const DEFAULT_PERIOD_DAYS = 30

function GeneralStats() {
  const theme = useContext(ThemeContext)

  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ days: DEFAULT_PERIOD_DAYS }),
    to: DateTime.now(),
  })

  const { data, loading } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const { ref, width } = useResizeDetector({ handleHeight: false })

  const { data: dataset, loading: datasetLoading } = useQuery(
    SYSTEM_DAILY_STATS_DATASET,
  )

  const systemDailyStatsDataByFields = useMemo(
    () => data?.systemDailyStatsGraphData || {},
    [data],
  )

  const systemDailyStatsDataset = useMemo(
    () => dataset?.systemDailyStatsDataset || {},
    [dataset],
  )

  const renderDiffBlock = useCallback(
    (diff: number, percentage?: number, withoutArrow?: boolean) => {
      if (isUndefined(diff)) return null
      return (
        <Box alignItems="baseline" display="flex">
          {!withoutArrow && !!diff && (
            <div>
              {diff > 0 ? (
                <ArrowUpwardIcon color="success" sx={{ mr: '12px' }} />
              ) : (
                <ArrowDownwardIcon color="error" sx={{ mr: '12px' }} />
              )}
            </div>
          )}
          <Text
            error={diff < 0}
            primary
            success={diff > 0}
            sx={{ mr: 1.5 }}
            title1
          >
            {getFormattedNumber(diff)}
          </Text>
          <Text body0 error={diff < 0} primary success={diff > 0}>
            {percentage ? `(${percentage.toFixed(2)}%)` : ''}
          </Text>
        </Box>
      )
    },
    [],
  )
  // todo in circulation supply i got total supply
  const totalSupply = useMemo(
    () => systemDailyStatsDataset?.circulationSupply || 0,
    [systemDailyStatsDataset],
  )

  const bridgedTokens = useMemo(
    () => systemDailyStatsDataset?.bridgedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const stakedTokens = useMemo(
    () => systemDailyStatsDataset?.stackedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const circulatingSupply = useMemo(
    () => totalSupply - (bridgedTokens + stakedTokens),
    [bridgedTokens, stakedTokens, totalSupply],
  )

  const bridgetCirculationChartData = useMemo(
    () =>
      !circulatingSupply && !stakedTokens && !bridgedTokens && !totalSupply
        ? []
        : [
            [
              'Tokens',
              'Amount',
              { role: 'tooltip', type: 'string', p: { html: true } },
            ],
            [
              'Staked',
              systemDailyStatsDataset?.stackedTokenAmount,
              generateCircularChartTooltipBlock(
                {
                  amount: stakedTokens,
                  percentage: calculatePercentage(stakedTokens, totalSupply),
                },
                theme.mode,
              ),
            ],
            [
              'Bridged',
              systemDailyStatsDataset?.bridgedTokenAmount,
              generateCircularChartTooltipBlock(
                {
                  amount: bridgedTokens,
                  percentage: calculatePercentage(bridgedTokens, totalSupply),
                },
                theme.mode,
              ),
            ],
            [
              'Total Supply',
              systemDailyStatsDataset?.circulationSupply,
              generateCircularChartTooltipBlock(
                {
                  amount: circulatingSupply,
                  percentage: calculatePercentage(
                    circulatingSupply,
                    totalSupply,
                  ),
                },
                theme.mode,
              ),
            ],
          ],
    [
      bridgedTokens,
      circulatingSupply,
      stakedTokens,
      systemDailyStatsDataset?.bridgedTokenAmount,
      systemDailyStatsDataset?.circulationSupply,
      systemDailyStatsDataset?.stackedTokenAmount,
      theme.mode,
      totalSupply,
    ],
  )

  if (loading || datasetLoading)
    return <CircularProgress size={20} sx={{ margin: '75px auto' }} />

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2.5} mb={2.5} ref={ref}>
        <Box
          display="grid"
          gap={2.5}
          gridTemplateColumns="25fr 12fr"
          overflow="hidden"
          width={width || '100%'}
        >
          <Box display="grid" gap={2.5} gridTemplateRows="324fr 148fr">
            <ChartContainer pb={1}>
              <Box p={2}>
                <Text body2 sx={{ mb: 0.5 }}>
                  MNW Bridged - Total
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300 }}>
                  Number of all bridged tokens
                </Text>
              </Box>
              <BridgedTokenChart
                chartData={systemDailyStatsDataByFields?.bridgedTokenAmount}
                diff={systemDailyStatsDataByFields?.bridgedTokenDailyDiff}
                // note: resolve problem with resize google-chart
                width={width ? `${(width / 3) * 2 - 20}px` : '100%'}
              />
            </ChartContainer>
            <Box display="grid" gap={2.5} gridTemplateColumns="1fr 1fr">
              <ChartContainer p={2}>
                <Text body2 sx={{ mb: 0.5 }}>
                  Running nodes
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  The number of all running nodes
                </Text>
                <Text primary sx={{ fontWeight: 500 }} title1>
                  {systemDailyStatsDataset?.totalActiveNodesCount || 0}
                </Text>
              </ChartContainer>
              <ChartContainer p={2}>
                <Text body2 sx={{ mb: 0.5 }}>
                  Nodes ++
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  Nodes added/removed on last day
                </Text>
                <Text primary sx={{ fontWeight: 500 }} title1>
                  {renderDiffBlock(
                    systemDailyStatsDataset?.nodesDailyDiff ?? 0,
                    systemDailyStatsDataset?.nodesDailyDIffPercentage ?? 0,
                  )}
                </Text>
              </ChartContainer>
            </Box>
          </Box>
          <Box display="grid" gap={2.5} gridTemplateRows="324fr 148fr">
            <ChartContainer
              // note: resolve problem with resize google-chart
              p={2}
              width={width ? `${width / 3}px` : '100%'}
            >
              <Box>
                <Text body2 sx={{ mb: 0.5 }}>
                  MNW Staked - Circul., %
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  Percentage tokens bridged of circulation supply
                </Text>
              </Box>
              <CirculationChart
                chartData={bridgetCirculationChartData}
                legendTitles={['Staked', 'Bridged', 'Total Supply']}
              />
            </ChartContainer>
            <ChartContainer p={2}>
              <Text body2 sx={{ mb: 0.5 }}>
                MNW Staked - Change
              </Text>
              <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                The difference in the amount of staked tokens over the last day
              </Text>
              <Text primary sx={{ fontWeight: 500 }} title1>
                {renderDiffBlock(
                  systemDailyStatsDataset?.tokensDailyDiff ?? 0,
                  systemDailyStatsDataset?.tokensDailyDiffPercentage ?? 0,
                )}
              </Text>
            </ChartContainer>
          </Box>
        </Box>
        <ChartContainer height="300px" p={2}>
          <Box>
            <Text body2 sx={{ mb: 0.5 }}>
              MNW Staked - Total
            </Text>
            <Text body3 secondary sx={{ fontWeight: 300 }}>
              The number of all tokens staked
            </Text>
          </Box>
          <TokenStakedBarChart
            chartDataStaked={systemDailyStatsDataByFields?.stackedNodesCount}
            chartDataUnstaked={systemDailyStatsDataByFields?.unstakedNodesCount}
          />
        </ChartContainer>
      </Box>
      <CompaniesBox />
      <NodesTotalBox />
    </>
  )
}

export default React.memo(GeneralStats)
