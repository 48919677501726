import { gql } from '@apollo/client'

import { NODE_EARNING_FIELDS } from './fragments/nodeEarningType'
import { NODE_INFO_FIELDS } from './fragments/nodeInfoType'

export const MY_NODES = gql`
  query MyNodes(
    $filter: NodesFilterInput
    $search: String
    $page: Int
    $take: Int
  ) {
    myNodes(filter: $filter, search: $search, page: $page, take: $take) {
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
      data {
        id
        address
        createdAt
        stakedAmount
        status
        transactionHash
        updatedAt
        workers
        reward
        nodeInfo {
          ...NodeInfoTypeFields
        }
      }
    }
  }
  ${NODE_INFO_FIELDS}
`

export const NODE_BY_ADDRESS = gql`
  query NodeByAddress($address: String!) {
    nodeByAddress(address: $address) {
      id
      active
      status
      stakedAmount
      createdAt
      lastActivity
      totalUptime
      transactionHash
      closesAt
      period
      workers
      unstakesAt
      earlyUnstaked
      reward
      penalty
      validatorId

      earnedRewards {
        ...NodeEarningTypeFields
      }
      projectedRewards {
        ...NodeEarningTypeFields
      }
      nodeInfo {
        ...NodeInfoTypeFields
      }
      providers {
        name
      }
      validator {
        id
        nodeAppId
      }
    }
  }
  ${NODE_EARNING_FIELDS}
  ${NODE_INFO_FIELDS}
`

export const GET_TOKENS_FROM_NODE_SYSTEM = gql`
  query GetTokensFromNodeSystem {
    getTokensFromNodeSystem {
      allTrustMnwTokens
      validatorNodesTokens
    }
  }
`

export const NODE_UPTIME_DATA = gql`
  query NodeUptime($nodeId: String!) {
    nodeUptime(nodeId: $nodeId) {
      items {
        key
        value
      }
      total
      totalTime
    }
  }
`

export const NODE_GLOBAL_STATS = gql`
  query NodeGlobalStats {
    nodeGlobalStats {
      allTimeNodes
      currentStakeLimit
      totalMnwStaked
      totalRewardsPaid
    }
  }
`

export const EARLY_UNSTAKE_PENALTY = gql`
  query EarlyUnstakePenalty($nodeId: String!) {
    earlyUnstakePenalty(nodeId: $nodeId) {
      reward
      penalty
    }
  }
`
