import React from 'react'

import { ReactComponent as TestQuerySuccessIcon } from 'assets/icons/test_query_success_icon.svg'
import { SubmitButton, Subtitle, Title } from 'globalStyles'

type Props = {
  onConfirm?: () => void
}

function TestQuerySuccess({ onConfirm }: Props) {
  return (
    <>
      <TestQuerySuccessIcon />
      <Title style={{ maxWidth: '530px', textAlign: 'center' }}>
        Congratulations, the test request was successful
      </Title>
      <Subtitle style={{ textAlign: 'center' }}>
        Settings are almost complete, just one step to go
      </Subtitle>
      <SubmitButton style={{ maxWidth: '320px' }} onClick={onConfirm}>
        Next
      </SubmitButton>
    </>
  )
}

export default TestQuerySuccess
