import { Box, linearProgressClasses } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/system'

export const Header = styled(Box)`
  border-radius: 8px 8px 0 0;
  background-color: ${({ theme }) => theme.palette.colors.bg.dropdown};
`

export const Content = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  height: 100%;
  flex: 1;
`

export const DailyStatsContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.secondary};
  display: flex;
`

export const BorderLinearProgress = styled(LinearProgress)`
  width: 276px;
  height: 4px;
  border-radius: 5px;

  &.${linearProgressClasses.colorPrimary} {
  background-color: #C5D1EA;
},
`
