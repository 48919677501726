import { gql } from '@apollo/client'

import { VALIDATOR } from './fragments'

export const UPDATE_VALIDATOR = gql`
  mutation UpdateValidatorWallet($wallet: String!) {
    updateValidatorWallet(wallet: $wallet) {
      wallet
    }
  }
`

export const REGISTER_VALIDATOR = gql`
  mutation Mutation($createNodeData: CreateNodeFields!) {
    registerValidatorNode(createNodeData: $createNodeData) {
      address
    }
  }
`

export const CONNECT_WALLET = gql`
  mutation ConnectWallet($wallet: String!, $signature: String!) {
    connectWallet(wallet: $wallet, signature: $signature) {
      id
      wallet
    }
  }
`

export const DISCONNECT_WALLET = gql`
  mutation DisconnectWallet {
    disconnectWallet {
      id
      wallet
    }
  }
`

export const GENERATE_NONCE = gql`
  mutation GenerateNonce {
    generateNonce
  }
`

export const UPDATE_VALIDATOR_USERNAME = gql`
  mutation UpdateValidatorUsername($name: String!) {
    updateValidatorUsername(name: $name) {
      ...Validator
    }
  }
  ${VALIDATOR}
`

export const DISCONNECT_TWITTER = gql`
  mutation DisconnectTwitter {
    disconnectTwitter {
      ok
    }
  }
`
