import React, { useContext, useMemo } from 'react'

import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactComponent as NotHaveNodesDM } from 'assets/icons/dont_have_nodes_dark.svg'
import { ReactComponent as NotHaveNodesLM } from 'assets/icons/dont_have_nodes_light.svg'
import InstructionsLink from 'components/InstructionsLink'
import { TOKENS_PER_NODE } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { UserBalanceContext } from 'context/userBalanceContext'
import { getMinWaitListTokens } from 'utils/numbers'

import { SubmitButton, Subtitle, Title } from './styles'

type Props = {
  onClickHandler: () => void
}

function NodeIntroduce({ onClickHandler }: Props) {
  const { me } = useAppContext()
  const mode = useContext(ThemeContext)
  const { balance, updating } = useContext(UserBalanceContext)

  const isDarkMode = mode.mode === 'dark'
  const NotHaveNodes = isDarkMode ? NotHaveNodesDM : NotHaveNodesLM

  const minWaitListTokens = useMemo(
    () => getMinWaitListTokens(TOKENS_PER_NODE),
    [],
  )

  if (me && me.stakedNode) return null

  if (updating) return <CircularProgress />

  if (balance.lt(minWaitListTokens))
    return (
      <Box alignItems="center" display="flex" flexDirection="column" mb={5}>
        <NotHaveNodes />
        <Title>You don&apos;t have enough MNWs</Title>
        <Subtitle sx={{ maxWidth: '500px' }}>
          Make sure to keep at least {TOKENS_PER_NODE} MNW on the Polygon
        </Subtitle>
      </Box>
    )

  return (
    <Box alignItems="center" display="flex" flexDirection="column" mb={5}>
      <NotHaveNodes />
      <Title>You don&apos;t have any nodes</Title>
      <Subtitle>To continue, you need to set the active node</Subtitle>
      <SubmitButton onClick={onClickHandler}>Setup Active Node</SubmitButton>

      <InstructionsLink />
    </Box>
  )
}

export default NodeIntroduce
