import buttons from './buttons'

import palette from '../palette'

const theme = () => ({
  transitionTime: {
    long: '400ms',
    default: '250ms',
    modal: '150ms',
  },

  colors: {
    bg: {
      default: palette.black,
      secondary: palette.blackSecondary,
      header: palette.darkViolet,
      blue: palette.darkBlue,
      template: palette.templateBg,
      dropdown: palette.violet,
      positive: palette.lightPositive,
      warning: palette.lightWarning,
      error: palette.lightError,
    },

    font: {
      default: palette.white,
      primary: palette.primary,
      secondary: palette.lightBlue,
      positive: palette.positive,
      error: palette.error,
      warning: palette.warning,
      template: palette.fontLightTemplate,
      muted: palette.muted,
      black: palette.black,
    },
    border: {
      default: palette.darkBlue,
      dark: palette.textDisabled,
      bright: palette.borderDark,
    },
  },
  buttons: buttons(),
})

export default theme
