import { Box, Link } from '@mui/material'
import { styled } from '@mui/system'
import { SubmitButton } from 'globalStyles'

export const InfoWrapper = styled(Box)`
  width: 100%;
  border-radius: 8px;
  display: flex;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
`

export const ViewOnExplorerLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

// todo need update with ui kit
export const ChangeButton = styled(SubmitButton)(() => ({
  height: '25px',
  margin: '0',
  fontWeight: 'normal',
  lineHeight: '12px',
  fontSize: '15px',
  padding: '15px 5px',
}))

export const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`
