const palette = {
  primary: '#4D75B8',

  black: '#000021',
  blackSecondary: '#0D0C2D',
  darkBlue: '#253566',
  lightBlue: '#8895AC',
  blue: '#e5edfd',
  secondaryBlue: '#D9E4F5',
  bgBlue: '#C5D1EA',

  darkViolet: '#1A1A37',
  violet: '#262546',

  lightDisabled: '#EDF0F5',
  lightDisabledText: '#7A89A6',
  white: '#FFFFFF',
  whiteSecondary: '#F7F9FF',
  whiteHeader: '#F0F3FF',
  templateBg: '#F4F8FB',
  borderDark: '#222249',
  borderLight: '#DEE8FC',
  fontLightTemplate: '#425D81',

  active: '#1F59BB',
  hover: '#6787BC',
  disabled: '#0C2042',
  textDisabled: '#1B3A6F',
  muted: '#5D6D9C',
  positive: '#26B568',
  lightPositive: 'rgba(38, 181, 104, 0.25)',
  warning: '#FFB406',
  lightWarning: 'rgba(255, 180, 6, 0.25)',
  error: '#E93940',
  lightError: 'rgba(233, 57, 64, 0.25)',
}

export default palette
