import { Box, Button, InputBase } from '@mui/material'
import { styled } from '@mui/system'

export const InputWrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}))

export const ArrowDownIconWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  border:
    theme.palette.mode === 'dark' ? '2px solid #253566' : '2px solid #E5EDFD',
  padding: '8px',
  borderRadius: '100%',
  left: '50%',
  top: '36%',
  transform: 'translateX(-50%)',
  width: '45px',
  height: '45px',
  zIndex: '1',
  background: theme.palette.mode === 'dark' ? '#000021' : 'white',
}))

export const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  height: '56px',
  borderRadius: '8px',
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(229, 237, 253, 1)' : '#253566'
  }`,
  background: theme.palette.mode === 'light' ? '' : '#0D0D26',
  marginTop: '20px',
  padding: '16px',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',

  '& svg *': {
    stroke: theme.palette.mode === 'light' ? 'rgba(229, 237, 253, 1)' : '',
  },
  '&.Mui-focused': {
    border: '2px solid rgba(77, 117, 184, 1)',

    '& .MuiInputBase-input::placeholder': {
      color: 'rgba(13, 13, 38, 1)',
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color:
      theme.palette.mode === 'light' ? 'rgba(185, 203, 238, 1)' : '#253566',
    fontSize: '16px',
  },
}))

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '56px',
  color: 'white',
  background: 'rgba(77, 117, 184, 1)',
  borderRadius: '8px',
  fontWeight: 'bold',
  marginTop: '24px',
  lineHeight: '24px',
  fontSize: '16px',
  textTransform: 'none',
  '&.Mui-disabled': {
    background:
      theme.palette.mode === 'light' ? 'rgba(237, 240, 245, 1)' : '#0C2042',
    color:
      theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 1)' : '#1B3A6F',
  },
}))

export const UlNodePopup = styled('ul')(() => ({
  margin: 0,
  padding: 0,
}))
