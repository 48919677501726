import { gql } from '@apollo/client'

export const VALIDATOR = gql`
  fragment Validator on Validator {
    id
    name
    nodeAppId
    wallet
  }
`
