import { Box, styled } from '@mui/system'

export const ButtonWrapper = styled(Box)`
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.palette.colors.border.dark};
`

export const FormulaWrapper = styled('div')`
  padding: 12px 0;
  font-size: 12px;
  span {
    font-weight: 600;
    font-family: 'Archivo', sans-serif;
  }
`
