import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const PaginationWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
  & > nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
