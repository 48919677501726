import { Link } from 'react-router-dom'

import { styled } from '@mui/system'

export const SocialLink = styled('a')`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border: ${({ theme }) => `1px solid ${theme.palette.colors.border.bright}`};
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  border-radius: 4px;
  height: 36px;
  width: 160px;
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  path {
    fill: ${({ theme }) => theme.palette.colors.font.secondary};
  }

  &:visited {
    color: ${({ theme }) => theme.palette.colors.font.secondary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.colors.font.default};
    path {
      fill: ${({ theme }) => theme.palette.colors.font.default};
    }
  }
`

export const TermsLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-decoration: none;
`
