import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'

export const MainContainer = styled('section')`
  background-color: ${({ theme }) => theme.palette.colors.bg.default};
  min-height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ContentBox = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1080px;
`

export const StickyButton = styled(Button)`
  position: fixed;
  top: calc(50% - 90px);
  right: 0;
  transform: rotate(270deg) translateY(-50%);
  transform-origin: center right;
  z-index: 1000;
  height: 40px;
  width: 187px;
`
