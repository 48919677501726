import ms from 'ms'

export const ALERT_DELAY = 5000

export const REWARDS_PERCENTAGE = 18

export const TOKENS_PER_NODE = 1800

export const PHASE_DURATION_IN_MONTHS = 1

export const REWARD_TIMES_IN_YEAR = 2

export const WARNING_UPTIME_LINE = 90

export const LOW_UPTIME_LINE = 50

export const ROWS_PER_PAGE_DEFAULT = 10

export const MIN_ROWS_PER_PAGE_DEFAULT = 5

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50]

export const INACTIVITY_NODE_PERIOD_MINUTES = 5

export const NODE_POLL_INTERVAL = ms('1m')

export const NODE_UPTIME_POLL_INTERVAL = ms('5m')

export const UNSUPPORTED_CHAIN_BANNER_HEIGHT = 68

export const STAGING_BANNER_HEIGHT = 51

export const HEADER_HEIGHT = 80

export const COMMUNITY_FEED_WIDTH = 520

export const UPTIME_THRESHOLD = 99

export const STAKE_ONBOARDING_MINUTES = 6
