import { gql } from '@apollo/client'

export const SYSTEM_DAILY_STATS_GRAPH_DATA = gql`
  query systemDailyStatsGraphData($from: DateTime, $to: DateTime) {
    systemDailyStatsGraphData(from: $from, to: $to) {
      availableNodesCount {
        value
        day
      }
      avgNodesCountPerValidator {
        value
        day
      }
      bridgedTokenAmount {
        value
        day
      }
      bridgedTokenDailyDiff {
        value
        day
      }
      bridgetTokenCirculationPercentage {
        value
        day
      }
      cliAppVersion {
        value
        day
      }
      closedValidatorsCount {
        value
        day
      }
      desktopAppVersion {
        value
        day
      }
      registeredValidatorsCount {
        value
        day
      }
      rewardsPaid {
        value
        day
      }
      stackedNodesCount {
        value
        day
      }
      stackedTokenAmount {
        value
        day
      }
      stackedTokenAmountCirculationPercentage {
        value
        day
      }
      stakedNodesDailyDiff {
        value
        day
      }
      tokenCapLeft {
        value
        day
      }
      tokenPrice {
        value
        day
      }
      totalActiveNodesCount {
        value
        day
      }
      totalActiveValidatorsCount {
        value
        day
      }
      totalLockedAmount {
        value
        day
      }
      unstakedNodesCount {
        value
        day
      }
      validatorsDailyDiff {
        value
        day
      }
    }
  }
`

export const SYSTEM_DAILY_STATS_DATASET = gql`
  query systemDailyStatsDataset {
    systemDailyStatsDataset {
      bridgedTokenAmount
      bridgedTokenDiff
      circulationSupply
      maxTrustMNWTokens
      stackedTokenAmount
      stakedTokenDiff
      tokenCapLeft
      totalActiveNodesCount
      totalActiveNodesDiff
      totalActiveValidatorsCount
      totalActiveValidatorsDiff
      totalValidatorsCount
      nodesDailyDiff
      nodesDailyDIffPercentage
      tokensDailyDiff
      tokensDailyDiffPercentage
      nodeStakedByUserCount {
        key
        value
      }
    }
  }
`
