import React, { MouseEventHandler, useCallback, useState } from 'react'

import { Box, Checkbox, FormControlLabel, Link } from '@mui/material'
import { LoadingButton } from 'components/loadingButton'
import { CustomModal } from 'components/modal'
import Text from 'components/ui/Text'

import { ButtonWrapper } from './styles'

import 'katex/dist/katex.min.css'

type Props = {
  isOpen?: boolean
  onClose?: () => void
  onConfirm?: () => void
}

function StakeTermsModal({ isOpen = false, onClose, onConfirm }: Props) {
  const [userAgreed, setUserAgreed] = useState<boolean>(false)

  const handleAgreementChange: MouseEventHandler<HTMLLabelElement> =
    useCallback(event => {
      // @ts-ignore
      setUserAgreed(event.target.checked)
    }, [])

  return (
    <CustomModal
      handleClose={onClose}
      open={isOpen}
      sx={{ padding: '56px 48px 40px', width: '650px', maxHeight: '100vh' }}
    >
      <Box>
        <Text sx={{ mb: 3 }} title1>
          Terms & Rewards{' '}
        </Text>
        <Box maxHeight="450px" mb={2} overflow="scroll">
          <Text body2 sx={{ fontWeight: 700, mb: 1.5 }}>
            Staking Mechanism
          </Text>
          <Text body4 sx={{ mb: 3 }}>
            Validators stake MNW tokens to participate in the MasterNodes
            program, with each node requiring a specific amount of MNW tokens
            (for instance, 1800 per node). This stake represents the validator’s
            weight within the Equitable Validator Leader Selector (EVLS)
            algorithm, affecting their chances of being selected to validate
            queries.
          </Text>

          <Text body2 sx={{ fontWeight: 700, mb: 1.5 }}>
            Staking Terms and Rewards
          </Text>

          <Text body4 fontWeight={700} sx={{ mb: 3 }}>
            Validators can choose to stake their MNW tokens for fixed terms of
            3, 6, or 12 months (Calculated as N+1 days where N is the stake
            duration). The reward per validated transaction varies based on the
            term of the stake:
          </Text>

          <Text body4 sx={{ fontWeight: 700, mb: 3 }}>
            <ul style={{ paddingLeft: '15px' }}>
              <li>3 months/90+1 days: 0.06 MNW per transaction</li>
              <li>6 months/180+1 days: 0.08 MNW per transaction</li>
              <li>12 months/365+1 days: 0.1 MNW per transaction</li>
            </ul>
          </Text>
          <Text body4>
            These rewards are gross amounts, and net earnings are calculated
            after deducting gas fees necessary for recording validations on the
            public blockchain.
          </Text>

          <Text body2 sx={{ fontWeight: 700, my: 1.5 }}>
            Validation Demand and Work Allocation
          </Text>

          <Text body4>
            The Trust.Supply API determines the required number of validations
            per query, which varies depending on the provider and the type of
            information being validated. Daily, the total amount of required
            validations (queries x validations) is scheduled, ensuring a
            predictable workload for validators.
          </Text>

          <Text body2 sx={{ fontWeight: 700, my: 1.5 }}>
            Work Allocation Process
          </Text>
          <Text body4>
            To maintain network stability and commitment, penalties for early
            unstaking are structured to discourage premature withdrawals while
            also adjusting rewards based on the completed duration of the stake
            relative to the committed term. All penalty fees will be added to
            the liquidity pool.
          </Text>

          <Text body2 sx={{ fontWeight: 700, my: 1.5 }}>
            Penalties for Early Unstaking
          </Text>
          <Text body4>
            To maintain network stability and commitment, penalties for early
            unstaking are structured to discourage premature withdrawals while
            also adjusting rewards based on the completed duration of the stake
            relative to the committed term. All penalty fees will be added to
            the liquidity pool.
          </Text>

          <Text body2 sx={{ fontWeight: 700, my: 1.5 }}>
            Penalty Structure
          </Text>

          <Text body4 sx={{ fontWeight: 700, mb: 1.5 }}>
            Early Unstaking Penalty:
          </Text>
          <Text body4 sx={{ mb: 3 }}>
            Regardless of the term length or when the unstaking occurs, there is
            a flat penalty of 10% on the net rewards amount for unstaking before
            the end of the commitment period.
          </Text>

          <Text body4 sx={{ fontWeight: 700, mb: 1.5 }}>
            Reward Adjustments:
          </Text>

          <Text body4 sx={{ mb: 3 }}>
            Rewards are adjusted based on the last completed term prior to
            unstaking. Any partial term does not count towards reward
            calculations.
          </Text>

          <Text body4 sx={{ fontWeight: 700, mb: 1.5 }}>
            Cooling-Off Period:
          </Text>

          <Text body4 sx={{ mb: 3 }}>
            In the case of early unstaking, equity and rewards will be held for
            30-35 days before being returned to the wallet of origin.
          </Text>

          <Text body2 sx={{ fontWeight: 700, mb: 1.5 }}>
            Specific Scenarios and Calculations:
          </Text>

          <Text body4 sx={{ mb: 3 }}>
            <strong>Example #1:</strong> Unstaking Before 3 Months (+1 day):
            <ul style={{ paddingLeft: '15px' }}>
              <li>Rewards: No rewards</li>
              <li>
                Scenario: A validator who stakes for any term but unstakes
                within 3 months and 1 day forfeits all rewards.
              </li>
            </ul>
          </Text>

          <Text body4 sx={{ mb: 3 }}>
            <strong>Example #2:</strong> Unstaking After 5 Months (6-Month
            Commitment):
            <ul style={{ paddingLeft: '15px' }}>
              <li>
                Rewards for Completed Term: Calculated at the rate applicable to
                a 3-month term (0.06 MNW per transaction)
              </li>
              <li>Penalty: 10% of the net rewards amount</li>
              <li>
                Scenario: If a validator commits to a 6-month term and unstakes
                at month 5, they receive rewards at the 3-month rate of 0.06 and
                a 10% penalty on the net rewards amount.
              </li>
            </ul>
          </Text>

          <Text body4 sx={{ mb: 3 }}>
            <strong>Example #3:</strong> Unstaking After 10 Months (12-Month
            Commitment):
            <ul style={{ paddingLeft: '15px' }}>
              <li>
                Rewards for Completed Term: Calculated at the rate applicable to
                a 6-month term (0.08 MNW per transaction)
              </li>
              <li>Penalty: 10% of the net rewards amount</li>
              <li>
                Scenario: If a validator commits to a 12-month term and unstakes
                at month 10, they receive rewards at the 6-month rate of 0.08
                and incur a 10% penalty on the net rewards amount
              </li>
            </ul>
          </Text>
          <Text body2 sx={{ fontWeight: 700, my: 1.5 }}>
            Uptime Requirements
          </Text>
          <Text body4>
            Maintaining high availability is critical for the functionality of
            the network. Validators are required to ensure an uptime of 99% or
            higher for their validating software, which is typically run on
            Virtual Private Servers (VPS). This high standard ensures that the
            network remains operational and capable of handling validation
            requests at all times, thereby maintaining its reliability and
            efficiency.
          </Text>
          <Text body2 sx={{ fontWeight: 700, my: 1.5 }}>
            Daily Uptime Requirement
          </Text>
          <Text body4>
            Immediate Consequences: Validators must meet the 99% uptime
            threshold daily to qualify for selection in the next validation day.
            Failure to meet this requirement results in the validator being
            ineligible for selection the following day, missing potential
            rewards. This slashing measure encourages validators to quickly
            address any issues that may affect their uptime.
          </Text>
          <Text body4 sx={{ fontWeight: 700, my: 1.5 }}>
            Long-Term Uptime Requirement
          </Text>
          <Text body4>
            <strong>End of Stake Term:</strong> If a validator&apos;s average
            uptime falls below 99% by the end of their staking term, they will
            forfeit any rewards accrued during that period. Instead of applying
            a penalty equal to the size of their stake, the forfeited rewards
            will be added to the rewards pool. These funds will then be
            redistributed in future validation cycles to other validators who
            meet the uptime requirements.
          </Text>
          <Text body4 sx={{ mt: 1 }}>
            <strong>Opportunity for Recovery:</strong>Validators have the entire
            term to monitor and improve their infrastructure to ensure
            consistent uptime. Meeting the daily requirement consistently after
            any downtime is crucial to avoiding reward forfeiture at the end of
            the staking term.
          </Text>

          <Text body4 sx={{ fontWeight: 700, my: 1.5, textDecoration: 'none' }}>
            The full description of the staking process can be found in the{' '}
            <Link
              color="primary"
              download="TrustSupply_Masternodes_Lightpaper.pdf"
              href={'/files/TrustSupply_Masternodes_Lightpaper.pdf'}
              sx={{
                textDecoration: 'none',
                fontWeight: '700',
                fontSize: '14px',
              }}
            >
              {' '}
              Trust.Supply Masternodes Lightpaper
            </Link>
            .
          </Text>

          <FormControlLabel
            control={<Checkbox style={{ paddingTop: 0, marginRight: 0 }} />}
            label="I fully understand and accept these terms and conditions"
            style={{ alignItems: 'start' }}
            onClick={handleAgreementChange}
          />
        </Box>

        <ButtonWrapper>
          <LoadingButton
            disabled={!userAgreed}
            error
            style={{ marginTop: '20px' }}
            text={'Continue'}
            onClick={onConfirm}
          />

          <LoadingButton
            secondary
            style={{ marginTop: '10px' }}
            text={'Cancel'}
            onClick={onClose}
          />
        </ButtonWrapper>
      </Box>
    </CustomModal>
  )
}

export default StakeTermsModal
