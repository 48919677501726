import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const CompaniesWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '8px',
  color: theme.palette.mode === 'light' ? 'rgba(0, 0, 33, 1)' : 'white',
  backgroundColor: theme.palette.colors.bg.header,
}))

export const CompaniesContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`

export const CompanyIconBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  padding: '10px',
  minHeight: 80,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000021',
  borderRadius: '8px',
}))

// TODO CHANGE FOR TEXT COMPONENT
export const Subtitle = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? 'rgba(122, 137, 166, 1)'
      : 'rgba(105, 122, 151, 1)',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '24px',
}))
