import React from 'react'

import { Box, Link, Tooltip } from '@mui/material'
import { CodeBlock } from 'components/codeBlock'

import { Subtitle, Title, YoutubeCustomizedIcon } from './styles'

function MacOSInstructions() {
  return (
    <Box>
      <Box alignItems="center" display="flex" sx={{ mt: 4 }}>
        <Title>First-time installation</Title>
        <Tooltip arrow title={'Click to see the video instructions.'}>
          <Link
            href={'https://youtu.be/-A8euv1AJWkTrustMNW'}
            sx={{ display: 'flex', ml: 1, mt: '3px' }}
            target="_blank"
          >
            <YoutubeCustomizedIcon />
          </Link>
        </Tooltip>
      </Box>
      <Subtitle>1. Install macOS package manager - Homebrew: </Subtitle>
      <CodeBlock
        code={
          '/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/master/install.sh)"'
        }
      />
      <Subtitle>2. Add Homebrew to system paths: </Subtitle>
      <CodeBlock
        code={
          'echo "# Homebrew\\nexport PATH=/opt/homebrew/bin:\\$PATH" >> .zshrc'
        }
      />
      <Subtitle>3. Update the current terminal paths: </Subtitle>
      <CodeBlock code={'source ~/.zshrc'} />
      <Subtitle>4. Install NVM via Homebrew: </Subtitle>
      <CodeBlock code={'brew install nvm'} />
      <Subtitle>5. Create a folder for NVM: </Subtitle>
      <CodeBlock code={'mkdir ~/.nvm'} />
      <Subtitle>6. Add path for NVM to System paths: </Subtitle>
      <CodeBlock
        code={
          'echo "export NVM_DIR=~/.nvm\\nsource \\$(brew --prefix nvm)/nvm.sh" >> .zshrc'
        }
      />
      <Subtitle>7. Add NVM to each Terminal opening instance: </Subtitle>
      <CodeBlock code={'source ~/.zshrc'} />
      <Subtitle>8. Install Node v20.9.0 using NVM: </Subtitle>
      <CodeBlock code={'nvm install  v20.9.0'} />
      <Subtitle>9. Make sure you use correct version of it: </Subtitle>
      <CodeBlock code={'nvm use v20.9.0'} />
      <CodeBlock code={'nvm list'} />
      <Subtitle sx={{ mt: '-13px' }}>
        Note: Should show correct version.{' '}
      </Subtitle>
      <Subtitle>10. Install NPM: </Subtitle>
      <CodeBlock code={'sudo apt install npm'} />
      <Subtitle>11. Install TrustMNW CLI: </Subtitle>
      <CodeBlock code={'npm install @morpheusnetwork/trustmnw-cli -g'} />
      <Subtitle>12. Run Morpheus CLI:</Subtitle>
      <CodeBlock code={'trustmnw-cli login'} />
      <Subtitle>
        The system will ask for your email address, and you will need to enter
        the verification code sent to your email address.
      </Subtitle>
      <Subtitle>13. Check status and confirm if it’s running:</Subtitle>
      <CodeBlock code={'trustmnw-cli status'} />
      <Subtitle>
        To exit status - just close tab of your terminal or use hotkey “ctrl +
        c”.
      </Subtitle>
      <Subtitle>
        14. Check-in 10 minutes on the web if it says Node is running.
      </Subtitle>
      <Title sx={{ mt: 4 }}>Update</Title>
      <Subtitle>1.Update CLI:</Subtitle>
      <CodeBlock code={'npm update @morpheusnetwork/trustmnw-cli -g'} />
      <Subtitle>2.Restart CLI:</Subtitle>
      <CodeBlock code={'trustmnw-cli restart'} />
      <Subtitle>3. Help command:</Subtitle>
      <CodeBlock code={'trustmnw-cli restart help'} />
    </Box>
  )
}

export default MacOSInstructions
