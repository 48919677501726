import React from 'react'

import CircleIcon from '@mui/icons-material/Circle'
import SyncIcon from '@mui/icons-material/Sync'
import { Box, Button, useTheme } from '@mui/material'
import { NodeInfoPopup } from 'components/nodeInfoPopup'
import Text from 'components/ui/Text'
import { WorkingNodeLoader } from 'components/workingNodeLoader'
import { StakingPeriod } from 'graphql/stakingTerms/types'

interface Props {
  active: boolean
  canResetNode?: boolean
  period?: StakingPeriod
  projectedRewards?: number
  onReset?: () => void
}

function IsNodeActive({
  active,
  canResetNode,
  period,
  projectedRewards,
  onReset,
}: Props) {
  const materialTheme = useTheme()

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box alignItems="center" display="flex" gap={1}>
        <Text title1>Node Details</Text>
        <NodeInfoPopup period={period} projectedRewards={projectedRewards} />

        {canResetNode && (
          <Box display="flex" height={20}>
            <Box
              bgcolor={materialTheme?.palette.primary.main}
              height="100%"
              mx="24px"
              width="1px"
            />

            <Button
              sx={{ p: '0', gap: '6px', textTransform: 'capitalize' }}
              onClick={onReset}
            >
              <SyncIcon sx={{ width: '16px', height: '16px' }} />
              Reset
            </Button>
          </Box>
        )}
      </Box>

      <Box alignItems="center" display="flex" gap={1}>
        {active ? (
          <>
            <Text body4 success>
              Active node
            </Text>
            <WorkingNodeLoader />
          </>
        ) : (
          <>
            <CircleIcon
              style={{
                color: 'red',
                fontSize: '15px',
                marginRight: '5px',
              }}
            />
            Node is not running!
          </>
        )}
      </Box>
    </Box>
  )
}

export default IsNodeActive
