import React, { useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import StakeTermsModal from 'components/blocks/modals/stakeTermsModal'
import { NodesStake } from 'components/blocks/nodesStake'
import { TestQuery } from 'components/blocks/testQuery'
import { TestQueryError } from 'components/blocks/testQueryError'
import { TestQuerySuccess } from 'components/blocks/testQuerySuccess'
import { Verification } from 'components/blocks/verification'
import { WalletConnect } from 'components/blocks/walletConnect'
import { LogoLoader } from 'components/logoLoader'
import { NodeIntroduce } from 'components/nodeIntroduce'
import { NodeList } from 'components/nodeList'
import { useAppContext } from 'context/AppContext'
import useWeb3 from 'hooks/useWeb3'
import { getProvider } from 'services/provider'

enum RegisterNodeSteps {
  VERIFICATION = 'verification',
  CONNECT_WALLET = 'connect_wallet',
  INTRODUCE = 'introduce',
  TEST_QUERY = 'test_query',
  TEST_QUERY_SUCCESS = 'test_query_success',
  TEST_QUERY_ERROR = 'test_query_error',
  NODE_STAKE = 'node_stake',
}

function NodesStakingStepper() {
  const { me } = useAppContext()
  const { active } = useWeb3()

  const [step, setStep] = useState<RegisterNodeSteps>(
    RegisterNodeSteps.INTRODUCE,
  )

  const [isOpenStakeTermsModal, setIsOpenStakeTermsModal] =
    useState<boolean>(false)

  const handleOpenStakeTermsModal = useCallback(
    () => setIsOpenStakeTermsModal(true),
    [],
  )

  const handleCloseStakeTermsModal = useCallback(
    () => setIsOpenStakeTermsModal(false),
    [],
  )

  const handleConfirmStakeTerms = useCallback(() => {
    setStep(RegisterNodeSteps.TEST_QUERY)
    setIsOpenStakeTermsModal(false)
  }, [])

  const provider = getProvider()

  useEffect(() => {
    if (!me?.isApproved) {
      setStep(RegisterNodeSteps.VERIFICATION)
      return
    }
    if (!active || !me?.wallet || !provider) {
      setStep(RegisterNodeSteps.CONNECT_WALLET)
    }
  }, [active, me, provider])

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        {step === RegisterNodeSteps.VERIFICATION && <Verification />}
        {step === RegisterNodeSteps.CONNECT_WALLET && (
          <WalletConnect
            onNextStep={() => setStep(RegisterNodeSteps.INTRODUCE)}
          />
        )}
        {step === RegisterNodeSteps.INTRODUCE && (
          <>
            {me?.wallet ? (
              <>
                <NodeIntroduce onClickHandler={handleOpenStakeTermsModal} />
                <NodeList />
              </>
            ) : (
              <LogoLoader />
            )}
          </>
        )}
        {step === RegisterNodeSteps.TEST_QUERY && (
          <TestQuery
            onErrorTest={() => setStep(RegisterNodeSteps.TEST_QUERY_ERROR)}
            onSuccessTest={() => setStep(RegisterNodeSteps.TEST_QUERY_SUCCESS)}
          />
        )}
        {step === RegisterNodeSteps.TEST_QUERY_SUCCESS && (
          <TestQuerySuccess
            onConfirm={() => setStep(RegisterNodeSteps.NODE_STAKE)}
          />
        )}
        {step === RegisterNodeSteps.TEST_QUERY_ERROR && (
          <TestQueryError
            onStartAgain={() => setStep(RegisterNodeSteps.INTRODUCE)}
          />
        )}
        {step === RegisterNodeSteps.NODE_STAKE && <NodesStake />}
      </Box>
      <StakeTermsModal
        isOpen={isOpenStakeTermsModal}
        onClose={handleCloseStakeTermsModal}
        onConfirm={handleConfirmStakeTerms}
      />
    </>
  )
}

export default NodesStakingStepper
