import { css, TypographyProps } from '@mui/material'
import { styled } from '@mui/system'

interface BaseColorProps {
  primary?: boolean
  secondary?: boolean
  success?: boolean
  warning?: boolean
  error?: boolean
  black?: boolean
  muted?: boolean
}

// todo need fix type for theme
interface CustomColorProps extends BaseColorProps {
  theme?: any
}
const primaryCss = ({ primary, theme }: CustomColorProps) =>
  primary &&
  css`
    color: ${theme.palette.colors.font.primary};
  `

const successCss = ({ success, theme }: CustomColorProps) =>
  success &&
  css`
    color: ${theme.palette.colors.font.positive};
  `
const secondaryCss = ({ secondary, theme }: CustomColorProps) =>
  secondary &&
  css`
    color: ${theme.palette.colors.font.secondary};
  `

const warningCss = ({ warning, theme }: CustomColorProps) =>
  warning &&
  css`
    color: ${theme.palette.colors.font.warning};
  `

const errorCss = ({ error, theme }: CustomColorProps) =>
  error &&
  css`
    color: ${theme.palette.colors.font.error};
  `

const blackCss = ({ black, theme }: CustomColorProps) =>
  black &&
  css`
    color: ${theme.palette.colors.font.black};
  `

const mutedCss = ({ muted, theme }: CustomColorProps) =>
  muted &&
  css`
    color: ${theme.palette.colors.font.muted};
  `

interface CustomTypographyProps {
  title1?: boolean
  title2?: boolean
  title3?: boolean
  title4?: boolean
  title5?: boolean
  subheader1?: boolean
  body0?: boolean
  body1?: boolean
  body2?: boolean
  body3?: boolean
  body4?: boolean
}

const body0Css = ({ body0 }: CustomTypographyProps) =>
  body0 &&
  css`
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  `
const body1Css = ({ body1 }: CustomTypographyProps) =>
  body1 &&
  css`
    font-size: 15px;
    line-height: 24px;
    font-weight: 200;
  `
const body2Css = ({ body2 }: CustomTypographyProps) =>
  body2 &&
  css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `

const body3Css = ({ body3 }: CustomTypographyProps) =>
  body3 &&
  css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  `

const body4Css = ({ body4 }: CustomTypographyProps) =>
  body4 &&
  css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  `

const subheader1Css = ({ subheader1 }: CustomTypographyProps) =>
  subheader1 &&
  css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  `

const title1Css = ({ title1 }: CustomTypographyProps) =>
  title1 &&
  css`
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
  `

const title2Css = ({ title2 }: CustomTypographyProps) =>
  title2 &&
  css`
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  `

const title3Css = ({ title3 }: CustomTypographyProps) =>
  title3 &&
  css`
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  `

const title4Css = ({ title4 }: CustomTypographyProps) =>
  title4 &&
  css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  `

const title5Css = ({ title5 }: CustomTypographyProps) =>
  title5 &&
  css`
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  `

interface ExtendedProps {
  ellipsis?: boolean
}
const ellipsisCss = ({ ellipsis }: ExtendedProps) =>
  ellipsis &&
  css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `

type Props = CustomColorProps &
  CustomTypographyProps &
  TypographyProps &
  ExtendedProps

const Text = styled('p')<Props>`
  margin: 0;
  padding: 0;
  position: relative;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.colors.font.default};

  ${title1Css}
  ${title2Css}
  ${title3Css}
  ${title4Css}
  ${title5Css}
  ${subheader1Css}
  ${body0Css}
  ${body1Css}
  ${body2Css}
  ${body3Css}
  ${body4Css}
  
  ${primaryCss}
  ${successCss}
  ${secondaryCss}
  ${warningCss}
  ${errorCss}
  ${blackCss}
  ${mutedCss}
  
  ${ellipsisCss}
`

export default Text
