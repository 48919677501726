import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { Box } from '@mui/system'
import { DetailNodeColumns } from 'components/detailNodeColumns'
import { NodeTable } from 'components/nodeTable'
import {
  NODE_UPTIME_POLL_INTERVAL,
  STAKE_ONBOARDING_MINUTES,
  UPTIME_THRESHOLD,
} from 'constants/params'
import { NODE_UPTIME_DATA } from 'graphql/nodes/queries'
import { NodeData, NodeStatus } from 'graphql/nodes/types'
import { DateTime, Duration } from 'luxon'

import NodePenaltiesBanner from '../NodePenaltiesBanner'

type Props = {
  address: string
  nodeData: NodeData
  onRefetchNode: (variables?: any) => Promise<any>
}

function NodeStats({ address, nodeData, onRefetchNode }: Props) {
  const { data, loading: loadingUptimeData } = useQuery(NODE_UPTIME_DATA, {
    variables: { nodeId: nodeData.id },
    pollInterval:
      nodeData.status === NodeStatus.ENABLED ? NODE_UPTIME_POLL_INTERVAL : 0,
    skip: !nodeData.id,
  })

  const totalUptimePercentage: number = useMemo(
    () => data?.nodeUptime?.total || 0,
    [data],
  )

  const uptime = useMemo(
    () =>
      data?.nodeUptime?.totalTime
        ? Duration.fromISO(data?.nodeUptime?.totalTime, {
            locale: 'en',
          }).toHuman({
            unitDisplay: 'short',
          })
        : '',
    [data],
  )

  const uptimeChartPoints = useMemo(() => data?.nodeUptime?.items, [data])

  const isOnboarding = useMemo(() => {
    if (nodeData?.status !== NodeStatus.ENABLED) return false
    const currentDate = DateTime.now()
    const timeDifference = currentDate.diff(
      DateTime.fromISO(nodeData.createdAt),
      'minutes',
    ).minutes

    return timeDifference < STAKE_ONBOARDING_MINUTES
  }, [nodeData])

  const isLowUptime = useMemo(() => {
    if (data?.nodeUptime) {
      return data?.nodeUptime?.total < UPTIME_THRESHOLD
    }
    return false
  }, [data])

  return (
    <>
      {!isOnboarding && (isLowUptime || nodeData.earlyUnstaked) && (
        <Box mb={2.5}>
          <NodePenaltiesBanner
            earlyUnstaked={nodeData.earlyUnstaked}
            status={nodeData.status}
            totalUptime={totalUptimePercentage}
            unstakesAt={nodeData?.unstakesAt}
          />
        </Box>
      )}

      <DetailNodeColumns
        address={address as string}
        isOnboarding={isOnboarding}
        loadingUptimeData={loadingUptimeData}
        nodeData={nodeData}
        totalUptimePercentage={totalUptimePercentage}
        uptime={uptime}
        uptimeChartPoints={uptimeChartPoints}
        onRefetchNode={onRefetchNode}
      />
      <NodeTable />
    </>
  )
}

export default React.memo(NodeStats)
