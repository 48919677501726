import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import detectEthereumProvider from '@metamask/detect-provider'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Divider } from '@mui/material'
import { ReactComponent as HeaderLogo } from 'assets/icons/main_logo.svg'
import { ReactComponent as MetaMaskIcon } from 'assets/icons/metamask_icon.svg'
import MorpheusFormIconDM from 'assets/icons/morpheus_form_icon_dark.svg'
import MorpheusFormIconLM from 'assets/icons/morpheus_form_icon_light.svg'
import { AccountMenu } from 'components/accountMenu'
import { AlertType } from 'components/alert/types'
import LogoName from 'components/LogoName'
import { RandomAvatar } from 'components/RandomAvatar'
import Text from 'components/ui/Text'
import { Provider } from 'constants/connector'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { UserBalanceContext } from 'context/userBalanceContext'
import { ethers } from 'ethers'
import { CONNECT_WALLET, GENERATE_NONCE } from 'graphql/validators/mutation'
import useWeb3 from 'hooks/useWeb3'
import { Routes } from 'router/routes'
import { getErrorMessage } from 'utils/error'
import { truncateAddress } from 'utils/truncateAddress'

import { SelectUserNameWrapper } from './styles'

// todo will be update later
import { WalletButton } from '../walletConnectList/styles'

function Header() {
  const { me, setUsername, showAlert } = useAppContext()
  const { balance } = useContext(UserBalanceContext)
  const { mode } = useContext(ThemeContext)
  const { connect, account, active, signMessage } = useWeb3()

  const [loading, setLoading] = useState(false)

  const [generateNonce] = useMutation(GENERATE_NONCE)
  const [connectWallet] = useMutation(CONNECT_WALLET)

  const isDarkMode = mode === 'dark'
  const MorpheusFormIcon = isDarkMode ? MorpheusFormIconDM : MorpheusFormIconLM

  const handleUnlock = async (provider: Provider) => {
    try {
      setLoading(true)
      const connectedProvider = await detectEthereumProvider()
      if (!connectedProvider?.isMetaMask) {
        showAlert?.({
          isOpen: true,
          title:
            'Please ensure you have the latest version of Metamask installed in your browser',
          color: AlertType.INFO,
        })
      } else {
        await connect(provider)
      }
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleConnect = async () => {
    try {
      if (me?.wallet) {
        return
      }

      const { data } = await generateNonce()

      const nonce = data?.generateNonce

      if (!nonce) throw new Error('Cannot generate nonce')

      const message = await signMessage(nonce)

      await connectWallet({
        variables: { wallet: account, signature: message },
      })
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    }
  }

  return (
    <>
      <Box alignItems="center" display="flex" gap={1.5} sx={{ flexGrow: 1 }}>
        <Link to={Routes.ROOT}>
          <HeaderLogo />
        </Link>
        <LogoName />
      </Box>

      {(!me?.wallet || !active) && (
        <>
          <WalletButton
            disabled={loading}
            style={{ width: 'auto' }}
            onClick={() =>
              active ? handleConnect() : handleUnlock(Provider.Injected)
            }
          >
            <MetaMaskIcon style={{ marginRight: '10px' }} />{' '}
            {active ? 'Connect' : 'Unlock'} MetaMask
          </WalletButton>
          <Divider flexItem orientation="vertical" sx={{ mx: 3, my: 2.5 }} />
        </>
      )}

      {me?.wallet && active && (
        <>
          <Box>
            <Box display="flex" gap={1}>
              <img alt="MNW" src={MorpheusFormIcon} width={20} />

              <Text body2> MNW: {ethers.utils.formatEther(balance)}</Text>
            </Box>
            <Text body3 secondary>
              {truncateAddress(me.wallet as string)}
            </Text>
          </Box>
          <Divider flexItem orientation="vertical" sx={{ mx: 3, my: 2.5 }} />
        </>
      )}

      <Box alignItems="center" display="flex" height="inherit">
        {me?.name ? (
          <Box alignItems="center" display="flex" gap={1}>
            <RandomAvatar name={me.name} />
            <Text body2 ellipsis sx={{ maxWidth: '150px' }}>
              {me.name}
            </Text>
          </Box>
        ) : (
          <>
            <Button
              sx={{ height: '40px' }}
              variant="text"
              onClick={setUsername}
            >
              <SelectUserNameWrapper gap={0.5}>
                <AddIcon />
                <Text body2 primary textTransform="initial">
                  Select username
                </Text>
              </SelectUserNameWrapper>
            </Button>
            <Divider flexItem orientation="vertical" sx={{ mx: 3, my: 2.5 }} />
          </>
        )}
        <AccountMenu />
      </Box>
    </>
  )
}

export default Header
