import React, { useCallback, useContext, useState } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import MorpheusFormIcon from 'assets/icons/morpheus_form_icon_light.svg'
import { AlertType } from 'components/alert/types'
import { UnstakeTermsModal } from 'components/blocks/modals'
import { LoadingButton } from 'components/loadingButton'
import Text from 'components/ui/Text'
import { WEB3 } from 'config'
import { useAppContext } from 'context/AppContext'
import { UserBalanceContext } from 'context/userBalanceContext'
import { EARLY_UNSTAKE } from 'graphql/nodes/mutation'
import { NodeData, NodeStatus } from 'graphql/nodes/types'
import pluralize from 'pluralize'
import { getDate } from 'utils/date'
import { getErrorMessage } from 'utils/error'
import { truncateAddress } from 'utils/truncateAddress'

import { Amount, CopyIcon, LinkIcon, NodeInfoContainer } from '../styles'

interface Props {
  nodeData: NodeData
  address: string
  totalUptimePercentage: number
  onRefetchNode: (variables?: any) => Promise<any>
}

function NodeInfo({
  nodeData,
  address,
  totalUptimePercentage,
  onRefetchNode,
}: Props) {
  const { setUpdating } = useContext(UserBalanceContext)

  const { refetchMe, showAlert } = useAppContext()

  const apolloClient = useApolloClient()

  const [earlyUnstake] = useMutation(EARLY_UNSTAKE)

  const [loading, setLoading] = useState<boolean>(false)
  const [confirmUnstakeModal, setConfirmUnstakeModal] = useState<{
    isOpen: boolean
    entity: NodeData | null
    totalUptimePercentage: number
  }>({
    isOpen: false,
    entity: null,
    totalUptimePercentage: 0,
  })

  const handleUnstake = async () => {
    setConfirmUnstakeModal({
      isOpen: false,
      entity: null,
      totalUptimePercentage: 0,
    })
    setLoading(true)
    if (!nodeData) return
    try {
      await earlyUnstake({
        variables: { nodeId: nodeData.id },
      })
      await onRefetchNode({ contractAddress: address })
      await apolloClient.refetchQueries({
        include: ['MyNodes'],
      })
      await refetchMe?.().then()
      setUpdating(true)
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCopy = useCallback(
    (address: string) => {
      navigator.clipboard.writeText(address)
      showAlert?.({
        isOpen: true,
        title: 'The address was copied',
        color: AlertType.INFO,
      })
    },
    [showAlert],
  )

  const handleCloseConfirmUnstake = useCallback(
    () =>
      setConfirmUnstakeModal({
        isOpen: false,
        entity: null,
        totalUptimePercentage: 0,
      }),
    [],
  )
  const handleOpenConfirmUnstake = useCallback(
    () =>
      setConfirmUnstakeModal({
        isOpen: true,
        entity: nodeData,
        totalUptimePercentage,
      }),
    [nodeData, totalUptimePercentage],
  )

  return (
    <>
      <NodeInfoContainer>
        <Box>
          <Text body2 sx={{ mb: 2 }}>
            Staked
          </Text>
          <Amount color={'colors.font.default'} mb={1}>
            {pluralize('node', nodeData.workers, true)}
          </Amount>
          <Text body4>Staked on</Text>
          <Text body4>{getDate(nodeData?.createdAt)}</Text>

          <Text body4 secondary sx={{ mb: 1, mt: 2 }}>
            Address:
          </Text>
          <Box sx={{ display: 'flex', mb: 2.5 }}>
            <Text body2>{truncateAddress(address)}</Text>
            <CopyIcon onClick={() => handleCopy(address)} />
            <a
              href={`${WEB3.POLYGONSCAN_URL}/tx/${nodeData.transactionHash}`}
              rel="noreferrer"
              target="_blank"
            >
              <LinkIcon />
            </a>
          </Box>
          <Text body4 secondary sx={{ mb: 1 }}>
            Total value staked:
          </Text>
          <Box sx={{ display: 'flex' }}>
            <img alt="MNW" src={MorpheusFormIcon} width={25} />
            <Amount color={'colors.font.positive'} ml={1}>
              {nodeData?.stakedAmount?.toLocaleString('en-US') || 0}
            </Amount>
          </Box>
        </Box>
        <Box mt={2} />
        {nodeData?.status === NodeStatus.ENABLED && (
          <LoadingButton
            disabled={loading}
            loading={loading}
            neutral
            style={{ marginTop: '16px' }}
            text={'Unstake'}
            onClick={handleOpenConfirmUnstake}
          />
        )}
      </NodeInfoContainer>
      {confirmUnstakeModal.isOpen && (
        <UnstakeTermsModal
          isOpen
          nodeData={confirmUnstakeModal?.entity}
          totalUptimePercentage={confirmUnstakeModal?.totalUptimePercentage}
          onClose={handleCloseConfirmUnstake}
          onConfirm={handleUnstake}
        />
      )}
    </>
  )
}

export default NodeInfo
