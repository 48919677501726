import * as React from 'react'
import { useCallback, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import LightModeIcon from '@mui/icons-material/LightMode'
import Logout from '@mui/icons-material/Logout'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import AntSwitch from 'components/AntSwitch'
import { ThemeContext } from 'context/themeMode'
import useLogout from 'hooks/useLogout'
import { Routes } from 'router/routes'

import { MenuContent } from './styles'

export default function AccountMenu() {
  const navigate = useNavigate()
  const { mode, toggleColorMode } = useContext(ThemeContext)

  const logout = useLogout()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNavigateInstructions = useCallback(
    () => navigate(Routes.INSTRUCTION_PAGE),
    [navigate],
  )
  const handleNavigateSettings = useCallback(
    () => navigate(Routes.SETTINGS),
    [navigate],
  )

  const handleNavigateLeaderboard = useCallback(
    () => navigate(Routes.LEADERBOARD),
    [navigate],
  )

  const handleChangeTheme = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation()
      toggleColorMode()
    },
    [toggleColorMode],
  )

  const handleDownloadLightPaper = useCallback(() => {
    const link = document.createElement('a')
    link.href = '/files/TrustSupply_Masternodes_Lightpaper.pdf'
    link.download = 'TrustSupply_Masternodes_Lightpaper.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          aria-controls={open ? 'account-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          size="small"
          sx={{ p: 0 }}
          onClick={handleClick}
        >
          {open ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <MenuContent
        PaperProps={{
          elevation: 0,
          sx: {
            color: 'primary',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            borderRadius: '8px',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem onClick={handleNavigateLeaderboard}>
          <ListItemIcon>
            <GroupOutlinedIcon color="secondary" />
          </ListItemIcon>
          Leaderboard
        </MenuItem>

        <MenuItem onClick={handleNavigateInstructions}>
          <ListItemIcon>
            <MenuBookIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          Instructions
        </MenuItem>

        <MenuItem onClick={handleDownloadLightPaper}>
          <ListItemIcon>
            <UploadFileOutlinedIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          Lightpaper
        </MenuItem>

        <MenuItem onClick={handleNavigateSettings}>
          <ListItemIcon>
            <SettingsOutlinedIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>

        <MenuItem
          onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
            event.stopPropagation()
          }
        >
          <ListItemIcon>
            {mode === 'dark' ? (
              <DarkModeOutlinedIcon color="secondary" fontSize="small" />
            ) : (
              <LightModeIcon color="warning" fontSize="small" />
            )}
          </ListItemIcon>
          {mode === 'dark' ? 'Dark theme' : 'Light theme'}

          <AntSwitch
            checked={mode === 'dark'}
            sx={{ ml: 2 }}
            onChange={handleChangeTheme}
          />
        </MenuItem>
        <Divider />
        <MenuItem color="error" onClick={logout}>
          <ListItemIcon>
            <Logout color="secondary" fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MenuContent>
    </>
  )
}
