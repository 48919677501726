import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import {
  AlertTitle,
  Box,
  Button,
  FormControlLabel,
  Link,
  Snackbar,
} from '@mui/material'
import { ReactComponent as Cross } from 'assets/icons/cross.svg'
import { ReactComponent as Logo } from 'assets/icons/login_logo-dark.svg'
import { Alert } from 'components/alert'
import { AlertMessage, AlertType } from 'components/alert/types'
import StyledCheckBox from 'components/ui/StyledCheckbox'
import StyledInput from 'components/ui/StyledInput'
import SubmitButton from 'components/ui/SubmitButton'
import Text from 'components/ui/Text'
import { ALERT_DELAY } from 'constants/params'
import { useFormik } from 'formik'
import { SIGN_UP_VALIDATOR } from 'graphql/auth/mutation'
import { Routes } from 'router/routes'
import { getErrorMessage } from 'utils/error'
import { emailValidationReg } from 'utils/validations'
import * as yup from 'yup'

enum FIELDS {
  EMAIL = 'email',
  CONFIRN_NEWS_LETTER = 'confirmNewsletter',
  CONFIRM_SYSTEM_LETTER = 'confirmSystemLetter',
  CONFIRM_TERMS = 'confirmTerms',
}

interface InitialValues {
  [FIELDS.EMAIL]: string
  [FIELDS.CONFIRM_TERMS]: boolean
  [FIELDS.CONFIRN_NEWS_LETTER]: boolean
  [FIELDS.CONFIRM_SYSTEM_LETTER]: boolean
}

const initialValues = {
  [FIELDS.EMAIL]: '',
  [FIELDS.CONFIRM_TERMS]: false,
  [FIELDS.CONFIRN_NEWS_LETTER]: false,
  [FIELDS.CONFIRM_SYSTEM_LETTER]: false,
}

function SignUp() {
  const navigate = useNavigate()

  const [signUpValidator, { loading }] = useMutation(SIGN_UP_VALIDATOR)
  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const validationSchema = useMemo(
    () =>
      yup.object({
        [FIELDS.EMAIL]: yup
          .string()
          .email('Invalid email')
          .matches(emailValidationReg, 'Invalid email')
          .required('Email is a required field'),
        [FIELDS.CONFIRM_TERMS]: yup
          .boolean()
          .required('You must accept the terms and conditions')
          .oneOf([true], 'You must accept the terms and conditions'),
        [FIELDS.CONFIRM_SYSTEM_LETTER]: yup
          .boolean()
          .required(
            'You must acknowledge and consent to receiving system emails',
          )
          .oneOf(
            [true],
            'You must acknowledge and consent to receiving system emails',
          ),
      }),
    [],
  )

  const onSubmit = async (values: InitialValues) => {
    try {
      await signUpValidator({
        variables: {
          [FIELDS.EMAIL]: values[FIELDS.EMAIL],
          [FIELDS.CONFIRN_NEWS_LETTER]: values[FIELDS.CONFIRN_NEWS_LETTER],
        },
      })
      navigate(Routes.LOGIN_CODE, { state: { email: values[FIELDS.EMAIL] } })
    } catch (error) {
      setAlert({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    }
  }

  const handleNavigateToSignIn = useCallback(
    () => navigate(Routes.SIGN_IN),
    [navigate],
  )

  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    getFieldProps,
    handleBlur,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  return (
    <>
      <Box width="370px">
        <Logo />
        <Text title1>
          Welcome to
          <br />
          TRUST.SUPPLY
        </Text>
        <Text body3 secondary sx={{ mt: 0.5 }}>
          Enter your email to Sign Up
        </Text>
        <form style={{ textAlign: 'start' }} onSubmit={handleSubmit}>
          <StyledInput
            endAdornment={
              values?.[FIELDS.EMAIL]?.length > 0 ? (
                <Cross
                  style={{ cursor: 'pointer' }}
                  onClick={() => setFieldValue(FIELDS.EMAIL, '')}
                />
              ) : undefined
            }
            name={FIELDS.EMAIL}
            placeholder="Email address"
            value={values[FIELDS.EMAIL]}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Text
            body4
            error
            sx={{
              mt: 1,
              mb: 1,
              height: '24px',
            }}
          >
            {touched[FIELDS.EMAIL] && errors[FIELDS.EMAIL]}
          </Text>

          <FormControlLabel
            control={
              <StyledCheckBox
                size="small"
                terms
                {...getFieldProps(FIELDS.CONFIRM_TERMS)}
                checked={values[FIELDS.CONFIRM_TERMS]}
              />
            }
            label={
              <Text body4>
                I have read and agree to the{' '}
                <Link
                  href={Routes.TERMS_PAGE}
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  Terms & Conditions
                </Link>
              </Text>
            }
            sx={{ width: '100%', alignItems: 'start' }}
            onClick={handleChange}
          />
          <Text
            body4
            error
            sx={{
              mt: 0.5,
              mb: 1.5,
            }}
          >
            {touched[FIELDS.CONFIRM_TERMS] && errors[FIELDS.CONFIRM_TERMS]}
          </Text>

          <FormControlLabel
            control={
              <StyledCheckBox
                size="small"
                terms
                {...getFieldProps(FIELDS.CONFIRM_SYSTEM_LETTER)}
                checked={values[FIELDS.CONFIRM_SYSTEM_LETTER]}
              />
            }
            label={
              <Text body4>
                I acknowledge and consent to receiving system emails necessary
                for the operation and maintenance of my account
              </Text>
            }
            sx={{ width: '100%', alignItems: 'start' }}
            onClick={handleChange}
          />
          <Text
            body4
            error
            sx={{
              mt: 0.5,
              mb: 1.5,
            }}
          >
            {touched[FIELDS.CONFIRM_SYSTEM_LETTER] &&
              errors[FIELDS.CONFIRM_SYSTEM_LETTER]}
          </Text>
          <FormControlLabel
            control={
              <StyledCheckBox
                size="small"
                terms
                {...getFieldProps(FIELDS.CONFIRN_NEWS_LETTER)}
                checked={values[FIELDS.CONFIRN_NEWS_LETTER]}
              />
            }
            label={
              <Text body4>
                I would like to receive updates, offers, and newsletters via
                email
              </Text>
            }
            sx={{ width: '100%', alignItems: 'start' }}
            onClick={handleChange}
          />
          <SubmitButton disabled={loading} type="submit">
            Sign Up
          </SubmitButton>
        </form>
        <Button
          sx={{ textTransform: 'none', width: 'fit-content', mt: 3, p: 0 }}
          variant="text"
          onClick={handleNavigateToSignIn}
        >
          <Text primary sx={{ fontSize: '16px' }} title3>
            I already have an account
          </Text>
        </Button>
      </Box>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert?.isOpen}
        onClose={() => setAlert(prevState => ({ ...prevState, isOpen: false }))}
      >
        <Alert
          severity={alert?.color}
          sx={{ width: '100%' }}
          onClose={() =>
            setAlert(prevState => ({ ...prevState, isOpen: false }))
          }
        >
          <AlertTitle sx={{ textAlign: 'left' }}>{alert.title}</AlertTitle>
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SignUp
