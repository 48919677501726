import React from 'react'
import { useLocation } from 'react-router-dom'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { WEB3 } from 'config'
import { UNSUPPORTED_CHAIN_BANNER_HEIGHT } from 'constants/params'
import { Routes } from 'router/routes'
import { getChainDetails } from 'utils/chains'

import { Alert } from '../alert'

type Props = {
  onSwitchChain?: () => void
}
function UnsupportedChainBanner({ onSwitchChain }: Props) {
  const location = useLocation()

  if (location.pathname === Routes.MONTHLY_REPORT) return null

  return (
    <Alert
      action={
        <Button
          size="small"
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '16px',
            height: '44px',
            width: '158px',
          }}
          variant="contained"
          onClick={onSwitchChain}
        >
          Switch Network
        </Button>
      }
      color="warning"
      icon={false}
      severity="warning"
      sx={{
        height: UNSUPPORTED_CHAIN_BANNER_HEIGHT,
        background: 'rgba(255, 180, 6, 1)',
        px: 4,
        borderRadius: 'initial',
      }}
    >
      <Box alignItems="center" display="flex" gap={1.5}>
        <WarningAmberIcon sx={{ fontSize: '20px' }} />
        <Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Unsupported chain
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: '300' }}>
            Please switch to {getChainDetails(WEB3.CHAIN_ID)?.name}
          </Typography>
        </Box>
      </Box>
    </Alert>
  )
}

export default UnsupportedChainBanner
