import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const BackgroundImage = styled(Box)`
  position: relative;
  height: 680px;
  object-fit: cover;
  margin: -50px 0 0 -50px;
  background-size: cover;
  background-position: center;
`

export const GradientWrapper = styled(Box)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(to top, rgba(14, 14, 40, 1), rgba(14, 14, 30, 0));
`

export const ActivitiesContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.colors.bg.header};
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.palette.colors.border.bright}`};
  overflow: hidden;
`

export const HeaderWrapper = styled(Box)`
  background-color: #000000;
`
