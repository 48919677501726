import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

import { ReactComponent as WaveDM } from 'assets/icons/wave_top.svg'
import { ReactComponent as WaveLM } from 'assets/icons/wave_top_lm.svg'
import { ThemeContext } from 'context/themeMode'

import { ContentBox, MainContainer, Wrapper } from './styles'

function Wave2Layout() {
  const mode = useContext(ThemeContext)
  const isDarkMode = mode.mode === 'dark'
  const Wave = isDarkMode ? WaveDM : WaveLM

  return (
    <MainContainer>
      <Wrapper as="main">
        <Wave
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
          }}
        />
        <ContentBox p={12}>
          <Outlet />
        </ContentBox>
      </Wrapper>
    </MainContainer>
  )
}

export default Wave2Layout
