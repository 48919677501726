import { Box, BoxProps, css } from '@mui/material'
import { styled } from '@mui/system'

// todo need fix type for theme
interface ExtendedProps {
  theme?: any
  completed?: boolean
  expired?: boolean
}

const completedCss = ({ completed, theme }: ExtendedProps) =>
  completed &&
  css`
    background-color: ${theme.palette.colors.bg.positive};
  `

const expiredCss = ({ expired, theme }: ExtendedProps) =>
  expired &&
  css`
    background-color: ${theme.palette.colors.bg.error};
  `

type Props = BoxProps & ExtendedProps

export const NewsCard = styled(Box)<Props>`
  background-color: ${({ theme }) => theme.palette.colors.bg.dropdown};
  border-radius: 8px;
  text-align: left;
  margin-top: 12px;
  cursor: pointer;

  ${expiredCss}
  ${completedCss}
`
