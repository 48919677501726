import React from 'react'

import { Box, Divider } from '@mui/material'
import { ReactComponent as DiscordIcon } from 'assets/icons/discord.svg'
import { ReactComponent as HeaderLogo } from 'assets/icons/main_logo.svg'
import { ReactComponent as ParagraphIcon } from 'assets/icons/paragraph.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg'
import { ReactComponent as WarpcastIcon } from 'assets/icons/warpcast.svg'
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg'
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg'
import LogoName from 'components/LogoName'
import Text from 'components/ui/Text'
import { Routes } from 'router/routes'

import { SocialLink, TermsLink } from './styles'

const socialLinks = [
  {
    id: 1,
    title: 'Website',
    url: 'https://morpheus.network',
    icon: <WebsiteIcon />,
  },
  {
    id: 2,
    title: 'X (Twitter)',
    url: 'https://x.com/MNWSupplyChain',
    icon: <TwitterIcon />,
  },
  {
    id: 3,
    title: 'Paragraph',
    url: 'https://paragraph.xyz/@morpheus_network',
    icon: <ParagraphIcon />,
  },
  {
    id: 4,
    title: 'Discord',
    url: 'https://discord.gg/morpheusnetwork',
    icon: <DiscordIcon />,
  },
  {
    id: 5,
    title: 'Warpcast',
    url: 'https://warpcast.com/morpheus-network',
    icon: <WarpcastIcon />,
  },
  {
    id: 6,
    title: 'Youtube',
    url: 'https://www.youtube.com/@MorpheusNetwork',
    icon: <YoutubeIcon />,
  },
]

export default function Footer() {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxWidth="1080px"
      pt={4}
      width="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box alignItems="center" display="flex" gap={1.5}>
          <HeaderLogo style={{ height: '20px' }} />
          <LogoName />
          <Text title4>・</Text>
          <Text title4>Ⓒ 2024</Text>
        </Box>
        <Box alignItems="center" display="flex" gap={4} justifyContent="center">
          <TermsLink to={Routes.TERMS_PAGE}> Terms & Conditions</TermsLink>
          {/* <TermsLink to={Routes.TERMS_PAGE}> Privacy policy</TermsLink> */}
        </Box>
      </Box>
      <Divider flexItem sx={{ my: 3 }} />
      <Text body3 secondary sx={{ pb: 1.5, width: '100%' }} textAlign="start">
        Morpheus.Network on social media:
      </Text>
      <Box
        alignItems="center"
        display="flex"
        gap={1.5}
        justifyContent="space-between"
        width="100%"
      >
        {socialLinks.map(link => (
          <SocialLink
            href={link.url}
            key={link.id}
            rel="noopener noreferrer"
            target="_blank"
          >
            {link.icon}
            {link.title}
          </SocialLink>
        ))}
      </Box>
    </Box>
  )
}
