import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1px;
  height: 104px;
  margin: 20px 0 0;
  width: 100%;
`
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.colors.bg.header};

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }
`

// TODO CHANGE FOR TEXT COMPONENT
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.colors.font.secondary};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.colors.font.default};

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`
