import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import { ReactComponent as ArrowDownIconDM } from 'assets/icons/arrow_down_icon_dark.svg'
import { ReactComponent as ArrowDownIconLM } from 'assets/icons/arrow_down_icon_light.svg'
import MorpheusFormIconDM from 'assets/icons/morpheus_form_icon_dark.svg'
import MorpheusFormIconLM from 'assets/icons/morpheus_form_icon_light.svg'
import { ReactComponent as NodesFormIconDM } from 'assets/icons/nodes_form_icon_dark.svg'
import { ReactComponent as NodesFormIconLM } from 'assets/icons/nodes_form_icon_light.svg'
import { AlertType } from 'components/alert/types'
import { Loader } from 'components/loader'
import StyledSelect from 'components/ui/StyledSelect'
import Text from 'components/ui/Text'
import { Provider } from 'constants/connector'
import { TOKENS_PER_NODE } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { ThemeContext } from 'context/themeMode'
import { UserBalanceContext } from 'context/userBalanceContext'
import { useFormik } from 'formik'
import { REGISTER_VALIDATOR_NODE } from 'graphql/nodes/mutation'
import { NODE_GLOBAL_STATS } from 'graphql/nodes/queries'
import { GET_STAKING_TERMS } from 'graphql/stakingTerms/queries'
import {
  StakingTerms,
  StakingTermsLabel,
  StakingTermsOption,
} from 'graphql/stakingTerms/types'
import useWeb3 from 'hooks/useWeb3'
import { getProvider } from 'services/provider'
import { sendApprove } from 'utils/contractConfig'
import { convertToBigNumber } from 'utils/convertToBigNumber'
import { disableLettersHandler } from 'utils/disableLetters'
import { getErrorMessage } from 'utils/error'
import { nodeToMnwConvert } from 'utils/numbers'
import * as yup from 'yup'

import map from 'lodash/map'

import {
  ArrowDownIconWrapper,
  InputWrapper,
  StyledInput,
  SubmitButton,
} from './styles'

interface InitialValues {
  nodes: number
  mnv: number | string
  period: string
}

const INITIAL_AMOUNT = 1

function NodesStakeForm() {
  const navigate = useNavigate()
  const materialTheme = useTheme()
  const mode = useContext(ThemeContext)
  const { connect, library } = useWeb3()
  const { me, refetchMe, showAlert } = useAppContext()
  const { setUpdating } = useContext(UserBalanceContext)

  const { data, loading } = useQuery(GET_STAKING_TERMS)
  const [registerNode] = useMutation(REGISTER_VALIDATOR_NODE)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isDarkMode = mode.mode === 'dark'
  const NodesFormIcon = isDarkMode ? NodesFormIconDM : NodesFormIconLM
  const MorpheusFormIcon = isDarkMode ? MorpheusFormIconDM : MorpheusFormIconLM
  const ArrowDownIcon = isDarkMode ? ArrowDownIconDM : ArrowDownIconLM

  const stakingTerms = useMemo<StakingTerms[]>(
    () => data?.getStakingTerms || [],
    [data],
  )

  const stakingTermsOptions = useMemo<StakingTermsOption[]>(
    () =>
      stakingTerms.map(period => ({
        value: period.key,
        label: StakingTermsLabel[period.key],
      })),
    [stakingTerms],
  )

  const initialValues: InitialValues = {
    nodes: INITIAL_AMOUNT,
    mnv: nodeToMnwConvert(INITIAL_AMOUNT, TOKENS_PER_NODE),
    period: '',
  }

  const validationSchema = useMemo(() => {
    return yup.object({
      nodes: yup
        .number()
        .required('Please enter node count')
        .min(1, 'You have to stake at least one node'),
      mnv: yup
        .number()
        .min(TOKENS_PER_NODE, `Minimum ${TOKENS_PER_NODE} MNW per node holder`),
      period: yup.string().required('Please choose staking period'),
    })
  }, [])

  const onSubmitHandler = async (values: InitialValues) => {
    if (me?.wallet) {
      try {
        const amount: number | string = values.mnv
        setIsLoading(true)
        showAlert?.({
          isOpen: true,
          title: 'Please wait.. this process can take up to 20 minutes.',
          color: AlertType.INFO,
        })
        await sendApprove(library, convertToBigNumber(amount))
        const { data } = await registerNode({
          variables: {
            createNodeData: {
              nodes: values.nodes,
              period: values.period,
            },
          },
          refetchQueries: [{ query: NODE_GLOBAL_STATS }],
        })

        const address = data?.registerValidatorNode?.address

        if (!address) {
          showAlert?.({
            isOpen: true,
            title: 'Oops, something went wrong...',
            color: AlertType.ERROR,
          })
        } else {
          setUpdating(true)
          navigate(`/detail-node/${address}`)
        }
        await refetchMe?.().then()
        setIsLoading(false)
      } catch (error) {
        showAlert?.({
          isOpen: true,
          title: getErrorMessage(error),
          color: AlertType.ERROR,
        })
        setIsLoading(false)
        if (error instanceof Error) {
          // eslint-disable-next-line no-console
          console.log(error.message)
        }
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitHandler,
  })

  const { setFieldValue, handleChange, values, handleSubmit, errors, touched } =
    formik

  const handleNodesCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (!value) {
      setFieldValue('mnv', '')
      setFieldValue('nodes', '')
      return
    }

    setFieldValue('mnv', nodeToMnwConvert(value, TOKENS_PER_NODE))
    setFieldValue('nodes', parseInt(value, 10))
  }

  useEffect(() => {
    const connectToWallet = async () => {
      const provider = getProvider()
      if (!provider) return
      connect(provider as Provider)
    }

    connectToWallet()
  }, [])

  if (loading)
    return (
      <Box display="flex">
        <CircularProgress size={20} sx={{ margin: '172px auto' }} />
      </Box>
    )

  return (
    <form style={{ position: 'relative' }} onSubmit={handleSubmit}>
      <InputWrapper>
        <StyledInput
          endAdornment={<NodesFormIcon />}
          name="nodes"
          placeholder="Min. 1 NODE"
          sx={{ mt: 0 }}
          value={values.nodes}
          onChange={handleNodesCount}
          onKeyPress={disableLettersHandler}
        />
        <ArrowDownIconWrapper>
          <ArrowDownIcon style={{ marginTop: '2px', marginLeft: '4px' }} />
        </ArrowDownIconWrapper>
        <StyledInput
          disabled
          endAdornment={<img alt="morpheusIcon" src={MorpheusFormIcon} />}
          name="mnv"
          sx={{ mt: '16px' }}
          value={values.mnv}
          onKeyPress={disableLettersHandler}
        />

        <Text
          body2
          error
          sx={{
            textAlign: 'left',
            mt: 1,
            height: '24px',
          }}
        >
          {errors.nodes}
        </Text>
      </InputWrapper>
      <Box>
        <Text body2 sx={{ textAlign: 'left', mb: 0.5, mt: 1 }}>
          Period
        </Text>
        <StyledSelect
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            PaperProps: {
              sx: {
                // todo remove after updating types for theme
                // @ts-ignore
                bgcolor: materialTheme?.palette?.colors.bg.dropdown,
                backgroundImage: 'none',
              },
            },
          }}
          form
          name="period"
          sx={{ width: '100%' }}
          value={values.period}
          onChange={handleChange}
        >
          {map(stakingTermsOptions, period => (
            <MenuItem key={period.value} value={period.value}>
              {period.label}
            </MenuItem>
          ))}
        </StyledSelect>
        <Text
          body2
          error
          success
          sx={{
            textAlign: 'left',
            my: 1,
            height: '24px',
          }}
        >
          {touched.period && errors.period}
        </Text>
      </Box>
      <SubmitButton
        disabled={loading || isLoading}
        endIcon={isLoading ? <Loader style={{ marginLeft: '20px' }} /> : ''}
        sx={{ mt: 2 }}
        type="submit"
      >
        Continue
      </SubmitButton>
    </form>
  )
}

export default NodesStakeForm
