import React from 'react'

import { Box } from '@mui/material'
import { NodesStakeForm } from 'components/nodesStakeForm'
import Text from 'components/ui/Text'

function NodesStake() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      width="370px"
    >
      <Text sx={{ mb: 2 }} title1>
        Place a stake
      </Text>
      <Box width="100%">
        <NodesStakeForm />
      </Box>
    </Box>
  )
}

export default NodesStake
