import React, { useCallback } from 'react'

import { Button, useTheme } from '@mui/material'
import CustomModal from 'components/modal/customModal'
import Text from 'components/ui/Text'
import { SubmitButton, Title } from 'globalStyles'

interface Props {
  isOpen?: boolean
  onClose?: (ok?: boolean) => void
}

function ResetNodeLockModal({ isOpen, onClose }: Props) {
  const materialTheme = useTheme()

  const handleClose = useCallback((ok: boolean) => onClose?.(ok), [onClose])

  return (
    <CustomModal
      handleClose={() => handleClose(false)}
      open={isOpen}
      sx={{ width: '570px' }}
    >
      <Title mb="12px" sx={{ maxWidth: '327px', textAlign: 'center' }}>
        Reset node lock
      </Title>

      <Text
        sx={{
          fontSize: '16px',
          mb: '24px',
          textAlign: 'center',
          maxWidth: '420px',
          color: materialTheme?.palette.text.primary,
          fontWeight: '300',
        }}
      >
        Are you sure you want to force logout(reset) all connected nodes by your
        account? It will result in downtime until you reconnect new node
      </Text>

      <SubmitButton
        sx={{ mb: '24px', mt: 0, width: '160px' }}
        onClick={() => handleClose(true)}
      >
        Reset
      </SubmitButton>

      <Button
        sx={{
          mb: '72px',
          color: materialTheme?.palette.text.primary,
          textTransform: 'capitalize',
        }}
        onClick={() => handleClose(false)}
      >
        Cancel
      </Button>
    </CustomModal>
  )
}

export default ResetNodeLockModal
