import { gql } from '@apollo/client'

import { VALIDATOR } from './fragments/validator'

export const EARLY_UNSTAKE = gql`
  mutation EarlyUnstake($nodeId: String!) {
    earlyUnstake(nodeId: $nodeId) {
      ok
    }
  }
`

export const REGISTER_VALIDATOR_NODE = gql`
  mutation RegisterValidatorNode($createNodeData: CreateNodeFields!) {
    registerValidatorNode(createNodeData: $createNodeData) {
      address
    }
  }
`

export const REMOVE_VALIDATOR_NODE_APP_ID = gql`
  mutation RemoveValidatorNodeAppId {
    removeValidatorNodeAppId {
      ...Validator
    }
  }
  ${VALIDATOR}
`
