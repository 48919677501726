import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import detectEthereumProvider from '@metamask/detect-provider'
import { Box } from '@mui/material'
import { ReactComponent as MetaMaskIcon } from 'assets/icons/metamask_icon.svg'
import { AlertType } from 'components/alert/types'
import Text from 'components/ui/Text'
import { WalletButton } from 'components/walletConnectList/styles'
import { Provider } from 'constants/connector'
import { useAppContext } from 'context/AppContext'
import { CONNECT_WALLET, GENERATE_NONCE } from 'graphql/validators/mutation'
import useWeb3 from 'hooks/useWeb3'
import { getErrorMessage } from 'utils/error'

type Props = {
  onNextStep?: () => void
}
function WalletConnect({ onNextStep }: Props) {
  const { connect, account, active, signMessage } = useWeb3()
  const { me, showAlert } = useAppContext()

  const [loading, setLoading] = useState(false)

  const [generateNonce] = useMutation(GENERATE_NONCE)
  const [connectWallet] = useMutation(CONNECT_WALLET)

  const handleUnlock = async (provider: Provider) => {
    try {
      setLoading(true)
      const connectedProvider = await detectEthereumProvider()

      if (!connectedProvider?.isMetaMask) {
        showAlert?.({
          isOpen: true,
          title:
            'Please ensure you have the latest version of Metamask installed in your browser',
          color: AlertType.INFO,
        })
      } else {
        await connect(provider)
        if (me?.wallet) {
          onNextStep?.()
          return
        }
      }
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleConnect = async () => {
    try {
      if (me?.wallet) {
        onNextStep?.()
        return
      }

      const { data } = await generateNonce()

      const nonce = data?.generateNonce

      if (!nonce) throw new Error('Cannot generate nonce')

      const message = await signMessage(nonce)

      await connectWallet({
        variables: { wallet: account, signature: message },
      })

      onNextStep?.()
    } catch (error) {
      showAlert?.({
        isOpen: true,
        title: getErrorMessage(error),
        color: AlertType.ERROR,
      })
    }
  }

  return (
    <Box
      alignItems="baseline"
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Box>
        <Text sx={{ textAlign: 'left', mb: 1 }} title1>
          Connect Wallet
        </Text>
        <Text body4 secondary sx={{ textAlign: 'left', mb: 4 }}>
          To continue, you need to connect your wallet
        </Text>
      </Box>
      <WalletButton
        disabled={loading}
        onClick={() =>
          active ? handleConnect() : handleUnlock(Provider.Injected)
        }
      >
        <MetaMaskIcon style={{ marginRight: '10px' }} />{' '}
        {active ? 'Connect' : 'Unlock'} MetaMask
      </WalletButton>
    </Box>
  )
}

export default WalletConnect
