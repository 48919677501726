import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useQuery } from '@apollo/client'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, SelectChangeEvent, useTheme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { LeaderboardTable } from 'components/blocks/tables'
import ContentBox from 'components/ContentBox'
import Subheader from 'components/Subheader'
import StyledSelect from 'components/ui/StyledSelect'
import Text from 'components/ui/Text'
import { ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { useCurrentPhaseContext } from 'context/phaseContext'
import { LEADERBOARD } from 'graphql/leaderboard/queries'
import { PHASES } from 'graphql/phase/queries'
import pluralize from 'pluralize'
import { Leaderboard } from 'types/leaderbord'
import { Meta } from 'types/meta'
import { Phase } from 'types/phase'
import { getDate } from 'utils/date'
import { phaseDaysLeft } from 'utils/phase'

import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

const params = {
  page: 1,
  take: ROWS_PER_PAGE_DEFAULT,
  order: 'DESC',
}

function LeaderboardPage() {
  const materialTheme = useTheme()
  const { currentPhase } = useCurrentPhaseContext()
  const [selectedPhase, setSelectedPhase] = useState<Phase | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT)

  const { data: phasesData } = useQuery(PHASES)

  const { data, loading, refetch } = useQuery(LEADERBOARD, {
    variables: {
      ...params,
      phaseId: selectedPhase?.id,
    },
    skip: !selectedPhase?.id,
  })

  useEffect(() => {
    if (currentPhase) {
      setSelectedPhase(currentPhase)
    }
  }, [currentPhase])

  const phases: Phase[] = useMemo(() => phasesData?.phases || [], [phasesData])

  const leaderboardData: Leaderboard[] = useMemo(
    () => data?.leaderboard?.data || [],
    [data],
  )

  const leaderboardMeta: Meta = useMemo(
    () => data?.leaderboard?.meta || {},
    [data],
  )

  const phasesByIndex = useMemo(() => keyBy(phases, 'index'), [phases])

  const handleSelectPhase = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      setSelectedPhase(phasesByIndex[value as number])
      setCurrentPage(1)
      refetch({
        phaseId: phasesByIndex[value as number]?.id,
        take: rowsPerPage,
        page: 1,
      })
    },
    [phasesByIndex, refetch, rowsPerPage],
  )

  const handleChangePage = useCallback(
    (event: ChangeEvent<unknown>, value: number) => {
      setCurrentPage(value)
      refetch({ take: rowsPerPage, page: value })
    },
    [refetch, rowsPerPage],
  )

  const handleRowsPerPageChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      refetch({
        take: value as number,
        page: 1,
      })
      setRowsPerPage(value as number)
      setCurrentPage(1)
    },
    [refetch],
  )

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Subheader py={4}>
        <Text sx={{ textAlign: 'left', mb: 1 }} title1>
          Leaderboard
        </Text>
        <Box display="flex">
          {!!phases.length && (
            <StyledSelect
              IconComponent={ExpandMoreIcon}
              MenuProps={{
                PaperProps: {
                  sx: {
                    // todo remove after updating types for theme
                    // @ts-ignore
                    bgcolor: materialTheme?.palette?.colors.bg.dropdown,
                    backgroundImage: 'none',
                    height: '300px',
                  },
                },
              }}
              defaultValue={currentPhase?.index}
              disableUnderline
              value={selectedPhase?.index}
              variant="standard"
              onChange={handleSelectPhase}
            >
              {map(phases, (phase: Phase) => (
                <MenuItem key={phase.id} value={phase.index}>
                  {`Phase ${phase.index}: ${getDate(
                    phase?.startDate,
                  )}-${getDate(phase?.endDate)} ${
                    phase.index === currentPhase.index
                      ? `(${pluralize(
                          'day',
                          phaseDaysLeft(phase.endDate),
                          true,
                        )} left)`
                      : ''
                  }`}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        </Box>
      </Subheader>
      <ContentBox>
        <LeaderboardTable
          count={leaderboardMeta?.itemCount ?? 0}
          currentPage={currentPage}
          leaderboardData={leaderboardData}
          loading={loading}
          pageCount={leaderboardMeta?.pageCount ?? 0}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </ContentBox>
    </Box>
  )
}

export default React.memo(LeaderboardPage)
