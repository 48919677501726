import { Box, Container } from '@mui/material'
import { styled } from '@mui/system'

export const MainContainer = styled('section')`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled(Container)`
  background-color: ${({ theme }) => theme.palette.colors.bg.default};
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-grow: 1;
`

export const ContentBox = styled(Box)`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
